import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const productApiSlice = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    fetchAllProducts: builder.query({
      query: () => '/products',
    }),
    fetchAllCategory: builder.query({
      query: () => '/category',
    }),
    fetchAllOffers: builder.query({
      query: () => '/coupon',
    }),
    fetchProductById: builder.query({
      query: (slug) => `/products/${slug}`,
    }),
    fetchProductsByCategory: builder.query({
      query: (id) => `/products/category/${id}`,
    }),
    fetchProductsByVendor: builder.query({
      query: (id) => `/products/vendor/${id}`,
    }),
  }),
});

export const { 
  useFetchAllProductsQuery,
  useFetchAllCategoryQuery,
  useFetchAllOffersQuery,
  useFetchProductByIdQuery,
  useFetchProductsByCategoryQuery,
  useFetchProductsByVendorQuery
} = productApiSlice;

export const productApiReducer = productApiSlice.reducer;

