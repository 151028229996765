import Slider1 from "../assets/img/slider-1.png"
import Slider2 from "../assets/img/slider-2.png"
import Slider3 from "../assets/img/slider-3.png"
export const SliderData = [
    {
      name: "Inhale thrill & Sensation",
      description: "Find your perfect match and embrace the thrill of every inhale.",
      img: Slider1,
      path:"biscuits--cakes"
    },
    {
        name: "Nicotine Flavor Fulfillment",
        description: "Discover a symphony of flavors tailored to your palate,",
        img: Slider2,
        path:"fish--meat"
      },
      {
        name: "Exhilaration Bliss Explore",
        description: "It's time to indulge and elevate your senses",
          img: Slider3,
          path:"fresh-vegetable"
      },
  ];