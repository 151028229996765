import React, { useEffect } from 'react'
import Table from '../table/Table'
import { useFetchOrderByUserQuery } from '../../store/api/OrderSlice';


const MyOrders = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const { data } = useFetchOrderByUserQuery();


  return (
    <div className="overlow-hidden">
      <h2 className="text-xl text-black font-semibold mb-5">My Orders</h2>
      <Table data={data} />
    </div>
  )
}

export default MyOrders