import { Field, Form, Formik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useResetPwdMutation } from '../../store/api/AuthSlice'

const PasswordReset = () => {
    const param = useParams();
    const [resetPwd, { isLoading }] = useResetPwdMutation();
    const navigate = useNavigate();

    const handleResetPassword = async (values) => {
        if (values?.password !== values?.confirmPassword) {
            toast.error('Password does not match');
        } else {
            const data = {
                newPassword: values?.password,
                token: param.token
            }
            const response = await resetPwd(data);
            if (response?.error) {
                toast.error(response.error.data?.message || 'Internal Server Error');
            } else {
                navigate('/');
                toast.success(response.data?.message || 'Something went wrong');
            }
        }
    }
    return (
        <section className='p-10'>
            <div className='shadow p-10 border-gray border max-w-2xl rounded-lg h-[300px] m-auto'>
                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: "",
                    }}
                    onSubmit={handleResetPassword}
                >
                    {({ errors, touched, isValidating }) => (
                        <Form className="flex flex-col justify-center">
                            <div className="grid grid-cols-1 gap-5">
                                <div className="grid">
                                    <label
                                        className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    height="1em"
                                                    width="1em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        x="3"
                                                        y="11"
                                                        width="18"
                                                        height="11"
                                                        rx="2"
                                                        ry="2"
                                                    ></rect>
                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <Field
                                            className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                            required
                                        />
                                    </div>
                                    {errors.password && touched.password && (
                                        <span className="text-red-400 text-sm mt-2">
                                            {errors.password}
                                        </span>
                                    )}
                                </div>
                                <div className="grid">
                                    <label
                                        className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                                        htmlFor="password"
                                    >
                                        Confirm Password
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                                <svg
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    height="1em"
                                                    width="1em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        x="3"
                                                        y="11"
                                                        width="18"
                                                        height="11"
                                                        rx="2"
                                                        ry="2"
                                                    ></rect>
                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <Field
                                            className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            type="text"
                                            required
                                        />
                                    </div>
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <span className="text-red-400 text-sm mt-2">
                                            {errors.confirmPassword}
                                        </span>
                                    )}
                                </div>
                                {!isLoading ? 
                                 <button
                                    type="submit"
                                    className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                                >
                                    Reset Your Password
                                </button>
                                :
                                <p
                                  
                                    className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                                >
                                   Loading...
                                </p>
                            }
                               

                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
        </section>
    )
}

export default PasswordReset