import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const orderApiSlice = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (data) => ({
        url: '/order/add',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in the header
        }
      }),
    }),
    fetchOrderById: builder.query({
      query: (id) => ({
        url: `/order/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in the header
        }
      }),
    }),
    fetchOrderByUser: builder.query({
      query: (id) => ({
        url: `/order`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token in the header
        }
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useFetchOrderByIdQuery,
  useFetchOrderByUserQuery,

} = orderApiSlice;

export const orderApiReducer = orderApiSlice.reducer;

