import { configureStore } from '@reduxjs/toolkit'
import { cartReducer } from './reducers/cartSlice'
import { isOpenReducer } from './reducers/isOpenSlice'
import { searchReducer } from './reducers/searchSlice'
import { shoppingCardReducer } from './reducers/shoppingCardSlice'
import { sidebarReducer } from './reducers/sidebarSlice'
import { productApiReducer, productApiSlice } from './api/ProductSlice'
import { authApiReducer, authApiSlice } from './api/AuthSlice'
import { authReducer } from './reducers/auth'
import { orderApiReducer, orderApiSlice } from './api/OrderSlice'
import { shippingApiReducer, shippingApiSlice } from './api/ShippingSlice'
import { tokenExpirationMiddleware } from './middleware/tokenExpirationMiddleware'

export const store = configureStore({
  reducer: {
    shoppingCard: shoppingCardReducer,
    sidebar: sidebarReducer,
    cart: cartReducer,
    search: searchReducer,
    isOpen: isOpenReducer,
    auth:authReducer,
    productsApi: productApiReducer,
    authApi: authApiReducer,
    orderApi:orderApiReducer,
    shippingApi:shippingApiReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productApiSlice.middleware, 
      authApiSlice.middleware, 
      orderApiSlice.middleware, 
      shippingApiSlice.middleware,
      tokenExpirationMiddleware
    ),
})