function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img", false, /\.(png|jpe?g|svg)$/)
);

export const Products = [
  {
    price: 12,
    discount: 0,
    tag: ["rainbow-chard", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fd",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-1.png"],

    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: -0,
    slug: "rainbow-chard",
    title: "Rainbow Chard",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-13T03:52:58.986Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["clementine", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fc",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-2.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "clementine",
    title: "Clementine",
    type: "Grocery",
    unit: "5ct",
    updatedAt: "2022-01-19T20:18:00.564Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["rainbow-peppers", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fa",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-3.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "rainbow-peppers",
    title: "Rainbow Peppers",
    type: "Grocery",
    unit: "4ct",
    updatedAt: "2022-06-19T14:37:33.108Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["blueberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f9",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-4.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 29,
    slug: "blueberry",
    title: "Blueberry",
    type: "Grocery",
    unit: "25qt",
    updatedAt: "2022-07-16T15:41:09.773Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["calabaza-squash", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f8",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-5.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 42,
    slug: "calabaza-squash",
    title: "Calabaza Squash",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-05T14:57:19.980Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["lettuce", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f7",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-6.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 83,
    slug: "lettuce",
    title: "Lettuce",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-07-11T05:18:44.963Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["radicchio", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f6",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-7.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "radicchio",
    title: "Radicchio",
    type: "Grocery",
    unit: "12ct",
    updatedAt: "2022-01-19T04:32:10.877Z",
    sku: "F0056",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["parsley", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f5",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-8.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 54,
    slug: "parsley",
    title: "Parsley",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-05T18:39:53.960Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["strawberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f4",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-9.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 13,
    slug: "strawberrie",
    title: "Strawberrie",
    type: "Grocery",
    unit: "25oz",
    updatedAt: "2022-07-15T11:19:42.480Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f3",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-10.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 76,
    slug: "cauliflower",
    title: "Cauliflower",
    type: "Grocery",
    unit: "35lb",
    updatedAt: "2022-06-29T00:55:21.217Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f2",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-11.png"],
    originalPrice: 11,
    parent: "Fruits & Vegetable",
    quantity: 110,
    slug: "organic-purple-cauliflower",
    title: "Organic Purple Cauliflower",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-06-17T15:38:38.622Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["squash", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f1",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-12.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "ahold-acorn-squash",
    title: "Ahold Acorn Squash",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2022-05-09T07:02:59.121Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["cabbage", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f0",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-13.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 89,
    slug: "bok-choy-cabbage",
    title: "Bok Choy Cabbage",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-07-12T18:06:19.481Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["strawberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ef",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-14.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 80,
    slug: "strawberries-package",
    title: "Strawberries Package",
    type: "Grocery",
    unit: "25oz",
    updatedAt: "2022-06-17T12:14:43.031Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["aloe-vera-leaf", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ee",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-15.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 94,
    slug: "aloe-vera-leaf",
    title: "Aloe Vera Leaf",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-06-17T12:14:43.031Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["pineapple", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ed",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-16.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 49,
    slug: "pineapple-imported",
    title: "Pineapple Imported",
    type: "Grocery",
    unit: "5lb",
    updatedAt: "2022-06-26T18:26:14.825Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["broccoli", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ec",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-17.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 145,
    slug: "organic-broccoli",
    title: "Organic Broccoli",
    type: "Grocery",
    unit: "10oz",
    updatedAt: "2022-06-06T17:25:20.435Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["avocado", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4eb",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-18.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 146,
    slug: "organic-pinkerton-avocado",
    title: "Organic Pinkerton Avocado",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-08T14:35:27.983Z",
    sku: "",
    __v: 0,
  },
  {
    price: 16,
    discount: 0,
    tag: ["kale", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ea",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-19.png"],
    originalPrice: 16,
    parent: "Fruits & Vegetable",
    quantity: 60,
    slug: "organic-kale",
    title: "Organic Kale",
    type: "Grocery",
    unit: "8oz",
    updatedAt: "2021-12-22T16:41:23.215Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["corn", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e9",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-20.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "yellow-sweet-corn",
    title: "Yellow Sweet Corn",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-01-19T04:28:13.089Z",
    sku: "F0055",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e8",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-21.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 89,
    slug: "green-cauliflower",
    title: "Green Cauliflower",
    type: "Grocery",
    unit: "12ct",
    updatedAt: "2022-06-08T13:13:13.079Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["kale", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e7",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-22.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 58,
    slug: "organic-greens-red-kale",
    title: "Organic Greens Red Kale",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-10T05:56:43.861Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e6",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-23.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 70,
    slug: "organic-green-cauliflower",
    title: "Organic Green Cauliflower",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.215Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["ginger", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e5",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-24.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "organic-ginger-root",
    title: "Organic Ginger Root",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.215Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["carrot", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e4",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-25.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 5,
    slug: "organic-baby-carrot",
    title: "Organic Baby Carrot",
    type: "Grocery",
    unit: "10oz",
    updatedAt: "2021-12-30T15:23:40.288Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["cherry-tomato", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e3",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-26.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 40,
    slug: "organic-cherry-tomato",
    title: "Organic Cherry Tomato",
    type: "Grocery",
    unit: "5qt",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 17,
    tag: ["bell-pepper", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e2",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-27.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 99,
    slug: "organic-bell-pepper",
    title: "Organic Bell Pepper",
    type: "Grocery",
    unit: "22ct",
    updatedAt: "2022-07-08T14:26:08.089Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["potatoes", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e1",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-28.png"],
    originalPrice: 11,
    parent: "Fruits & Vegetable",
    quantity: 180,
    slug: "potatoes",
    title: "Potatoes",
    type: "Grocery",
    unit: "2lb",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["mint", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e0",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-29.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 140,
    slug: "mint",
    title: "Mint",
    type: "Grocery",
    unit: "5ct",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 16.666666666666664,
    tag: ["pumpkin", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4df",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-30.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 118,
    slug: "mini-pumpkin",
    title: "Mini Pumpkin",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-08T14:26:08.090Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["radish", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4de",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-31.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 160,
    slug: "organic-white-radish",
    title: "Organic White Radish",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["kale", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4dd",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-32.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 6,
    slug: "lacinato-dinosaur-kale",
    title: "Lacinato Dinosaur Kale",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-30T15:22:47.125Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 7,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4dc",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-33.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 84,
    slug: "iglotex-cauliflower",
    title: "Iglotex Cauliflower",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-14T12:27:24.539Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 8.333333333333332,
    tag: ["beans", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4db",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-34.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "french-green-beans",
    title: "French Green Beans",
    type: "Grocery",
    unit: "16ct",
    updatedAt: "2022-01-20T05:19:07.596Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["bulb", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4da",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-35.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 120,
    slug: "fennel-bulb",
    title: "Fennel Bulb",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 7,
    tag: ["escarole", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d9",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-36.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 109,
    slug: "escarole",
    title: "Escarole",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2022-05-09T16:11:52.335Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["broccoli", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d8",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-37.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 6,
    slug: "chinese-broccoli",
    title: "Chinese Broccoli",
    type: "Grocery",
    unit: "2lb",
    updatedAt: "2021-12-30T15:23:11.096Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["corn", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d7",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-38.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "bicolor-sweet-corn",
    title: "Bicolor Sweet Corn",
    type: "Grocery",
    unit: "43ct",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["asparagus", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d6",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-39.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "asparagus",
    title: "Asparagus",
    type: "Grocery",
    unit: "15oz",
    updatedAt: "2021-12-22T16:41:23.214Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 20,
    tag: ["mango", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d5",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-40.png"],
    originalPrice: 10,
    parent: "Fruits & Vegetable",
    quantity: 170,
    slug: "haitian-mango",
    title: "Haitian Mangoe",
    type: "Grocery",
    unit: "8ct",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["dates", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d4",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-41.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 130,
    slug: "fresh-dates",
    title: "Fresh Dates",
    type: "Grocery",
    unit: "10oz",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 13,
    tag: ["cranberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d3",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-42.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "cranberries",
    title: "Cranberries",
    type: "Grocery",
    unit: "9.5oz",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 8,
    tag: ["clementine", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d2",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-43.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 168,
    slug: "mandarin-clementine",
    title: "Mandarin Clementine",
    type: "Grocery",
    unit: "22lb",
    updatedAt: "2022-05-09T23:38:03.700Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["avocado", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d1",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-44.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "avocado-juice-fruit",
    title: "Avocado Juice Fruit",
    type: "Grocery",
    unit: "11lb",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["plum", "dry fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d0",
    children: "Dry Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. ... Nearly half of the dried fruits sold are raisins, followed by dates, prunes, figs, apricots, peaches, apples, and pears.",
    image: images["product-45.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "golden-garden-love-plum",
    title: "Golden Garden Love Plum",
    type: "Grocery",
    unit: "220g",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["blueberry", "dry fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4cf",
    children: "Dry Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. ... Nearly half of the dried fruits sold are raisins, followed by dates, prunes, figs, apricots, peaches, apples, and pears.",
    image: images["product-46.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 110,
    slug: "golden-garden-plueberry-plum",
    title: "Golden Garden Blueberry Plum",
    type: "Grocery",
    unit: "220g",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["fig", "dry fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ce",
    children: "Dry Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. ... Nearly half of the dried fruits sold are raisins, followed by dates, prunes, figs, apricots, peaches, apples, and pears.",
    image: images["product-47.png"],
    originalPrice: 11,
    parent: "Fruits & Vegetable",
    quantity: 99,
    slug: "dry-fig-loose",
    title: "Dry Fig Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-06-01T05:25:35.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["dates", "dry fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4cd",
    children: "Dry Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. ... Nearly half of the dried fruits sold are raisins, followed by dates, prunes, figs, apricots, peaches, apples, and pears.",
    image: images["product-48.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 99,
    slug: "dates-loose",
    title: "Dates Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-05-23T08:36:45.690Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["dates", "dry fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4cc",
    children: "Dry Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators. ... Nearly half of the dried fruits sold are raisins, followed by dates, prunes, figs, apricots, peaches, apples, and pears.",
    image: images["product-49.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "dates-iranian-loose",
    title: "Dates Iranian Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["organic food", "paka-pape"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4cb",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-50.png"],
    originalPrice: 12,
    parent: "Organic Food",
    quantity: 70,
    slug: "paka-pape",
    title: "Paka Pape",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-01-20T05:16:23.736Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 9,
    discount: 10,
    tag: ["organic food", "orange"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ca",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-51.png"],
    originalPrice: 10,
    parent: "Organic Food",
    quantity: 64,
    slug: "orange-imported",
    title: "Orange Imported",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-06-29T18:21:54.036Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 9.090909090909092,
    tag: ["organic food", "china apple"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c9",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-52.png"],
    originalPrice: 11,
    parent: "Organic Food",
    quantity: 59,
    slug: "china-fuji-apple",
    title: "China Fuji Apple",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-07-15T19:26:14.502Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["organic food", "gerber-organic"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c8",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-53.png"],
    originalPrice: 12,
    parent: "Organic Food",
    quantity: 67,
    slug: "gerber-organic",
    title: "Gerber-Organic",
    type: "Grocery",
    unit: "220ml",
    updatedAt: "2022-05-14T04:24:31.874Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 16.666666666666664,
    tag: ["organic food", "orange-jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c7",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-54.png"],
    originalPrice: 12,
    parent: "Organic Food",
    quantity: 65,
    slug: "sugar-free-orange-jelly",
    title: "Sugar Free Orange Jelly",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2022-06-11T11:44:56.199Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["organic food", "pomelo"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c6",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-55.png"],
    originalPrice: 6,
    parent: "Organic Food",
    quantity: 70,
    slug: "pomelo",
    title: "Pomelo",
    type: "Grocery",
    unit: "1pcs",
    updatedAt: "2021-12-22T16:41:23.212Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 9,
    tag: ["organic food", "pineapple"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c5",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-56.png"],
    originalPrice: 11,
    parent: "Organic Food",
    quantity: 69,
    slug: "pineapple",
    title: "Pineapple",
    type: "Grocery",
    unit: "1pcs",
    updatedAt: "2022-06-09T03:33:49.059Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["organic food", "guava-jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c4",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-57.png"],

    originalPrice: 11,
    parent: "Organic Food",
    quantity: 64,
    slug: "sugar-free-guava-jelly",
    title: "Sugar Free Guava Jelly",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2022-06-09T11:41:56.874Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c3",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-58.png"],
    originalPrice: 12,
    parent: "Fish & Meat",
    quantity: 93,
    slug: "wild-king-salmon-steak",
    title: "Wild King Salmon Steak",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-07-12T17:14:59.525Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c2",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-59.png"],
    originalPrice: 12,
    parent: "Fish & Meat",
    quantity: 74,
    slug: "canada-salmon",
    title: "Canada Salmon",
    type: "Grocery",
    unit: "5oz",
    updatedAt: "2022-07-02T07:19:00.309Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c1",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-60.png"],
    originalPrice: 15,
    parent: "Fish & Meat",
    quantity: -0,
    slug: "atlantic-salmon",
    title: "Atlantic Salmon",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-18T13:15:39.812Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c0",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-61.png"],
    originalPrice: 12,
    parent: "Fish & Meat",
    quantity: 0,
    slug: "salmon-filet-portion",
    title: "Salmon Filet Portion",
    type: "Grocery",
    unit: "6oz",
    updatedAt: "2022-05-31T13:52:17.036Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["ling-cod", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4bf",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-61.png"],
    originalPrice: 11,
    parent: "Fish & Meat",
    quantity: 51,
    slug: "ling-cod-steak",
    title: "Ling Cod Steak",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-06-09T03:33:49.059Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["shrimp", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4be",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-62.png"],
    originalPrice: 12,
    parent: "Fish & Meat",
    quantity: 68,
    slug: "peeled-deveined-shrimp",
    title: "Peeled Deveined Shrimp",
    type: "Grocery",
    unit: "2lb",
    updatedAt: "2022-06-09T03:33:49.059Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["sea-bass", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4bd",
    children: "Fish",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-63.png"],
    originalPrice: 14,
    parent: "Fish & Meat",
    quantity: 47,
    slug: "chilean-sea-bass",
    title: "Chilean Sea Bass",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-06-09T03:33:49.059Z",
    sku: "",
    __v: 0,
  },
  {
    price: 18,
    discount: 0,
    tag: ["beef", "meat"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4bc",
    children: "Meat",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Beef, flesh of mature cattle, as distinguished from veal, the flesh of calves. The best beef is obtained from early maturing, special beef breeds. High-quality beef has firm, velvety, fine-grained lean, bright red in colour and well-marbled. The fat is smooth, creamy white, and well distributed.",
    image: images["product-64.png"],
    originalPrice: 18,
    parent: "Fish & Meat",
    quantity: 57,
    slug: "beef-stew",
    title: "Beef Stew",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-06-22T09:44:06.495Z",
    sku: "",
    __v: 0,
  },
  {
    price: 19,
    discount: 0,
    tag: ["beef", "meat"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4bb",
    children: "Meat",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Beef, flesh of mature cattle, as distinguished from veal, the flesh of calves. The best beef is obtained from early maturing, special beef breeds. High-quality beef has firm, velvety, fine-grained lean, bright red in colour and well-marbled. The fat is smooth, creamy white, and well distributed.",
    image: images["product-65.png"],
    originalPrice: 19,
    parent: "Fish & Meat",
    quantity: 10,
    slug: "ground-turkey",
    title: "Ground Turkey",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-30T15:19:50.713Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["duck", "meat"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ba",
    children: "Meat",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Beef, flesh of mature cattle, as distinguished from veal, the flesh of calves. The best beef is obtained from early maturing, special beef breeds. High-quality beef has firm, velvety, fine-grained lean, bright red in colour and well-marbled. The fat is smooth, creamy white, and well distributed.",
    image: images["product-66.png"],
    originalPrice: 15,
    parent: "Fish & Meat",
    quantity: 40,
    slug: "duck-meat",
    title: "Duck Meat",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 19,
    discount: 0,
    tag: ["beef", "meat"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b9",
    children: "Meat",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Beef, flesh of mature cattle, as distinguished from veal, the flesh of calves. The best beef is obtained from early maturing, special beef breeds. High-quality beef has firm, velvety, fine-grained lean, bright red in colour and well-marbled. The fat is smooth, creamy white, and well distributed.",
    image: images["product-67.png"],
    originalPrice: 19,
    parent: "Fish & Meat",
    quantity: 60,
    slug: "boneless-beef",
    title: "Boneless Beef",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 18,
    discount: 0,
    tag: ["beef", "meat"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b8",
    children: "Meat",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Beef, flesh of mature cattle, as distinguished from veal, the flesh of calves. The best beef is obtained from early maturing, special beef breeds. High-quality beef has firm, velvety, fine-grained lean, bright red in colour and well-marbled. The fat is smooth, creamy white, and well distributed.",
    image: images["product-68.png"],
    originalPrice: 18,
    parent: "Fish & Meat",
    quantity: 40,
    slug: "bone-in-chuck",
    title: "Bone In Chuck",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["marley", "coffee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b7",
    children: "Coffee",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. ... Coffee is darkly colored, bitter, slightly acidic and has a stimulating effect in humans, primarily due to its caffeine content.",
    image: images["product-69.png"],
    originalPrice: 6,
    parent: "Drinks",
    quantity: 100,
    slug: "marley-coffee",
    title: "Marley Coffee",
    type: "Grocery",
    unit: "8oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["cafe-escapes", "coffee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b6",
    children: "Coffee",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. ... Coffee is darkly colored, bitter, slightly acidic and has a stimulating effect in humans, primarily due to its caffeine content.",
    image: images["product-70.png"],
    originalPrice: 9,
    parent: "Drinks",
    quantity: 100,
    slug: "cafe-escapes-coffee",
    title: "Cafe Escapes Coffee",
    type: "Grocery",
    unit: "8oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["caffe-kimbo", "coffee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b5",
    children: "Coffee",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. ... Coffee is darkly colored, bitter, slightly acidic and has a stimulating effect in humans, primarily due to its caffeine content.",
    image: images["product-71.png"],
    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "caffe-kimbo-coffee",
    title: "Caffe Kimbo Coffee",
    type: "Grocery",
    unit: "8oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["just", "coffee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b4",
    children: "Coffee",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. ... Coffee is darkly colored, bitter, slightly acidic and has a stimulating effect in humans, primarily due to its caffeine content.",
    image: images["product-72.png"],
    originalPrice: 8,
    parent: "Drinks",
    quantity: 100,
    slug: "just-coffee",
    title: "Just Coffee",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["caffe-perruci", "coffee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b3",
    children: "Coffee",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Coffee is a brewed drink prepared from roasted coffee beans, the seeds of berries from certain Coffea species. ... Coffee is darkly colored, bitter, slightly acidic and has a stimulating effect in humans, primarily due to its caffeine content.",
    image: images["product-73.png"],
    originalPrice: 10,
    parent: "Drinks",
    quantity: 100,
    slug: "caffe-perruci-coffee",
    title: "Caffe Perruci Coffee",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["positive-drinks", "energy drinks"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b2",
    children: "Energy Drinks",
    createdAt: "2021-12-22T16:41:23.211Z",
    description:
      "Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.",
    image: images["product-74.png"],
    originalPrice: 9,
    parent: "Drinks",
    quantity: 100,
    slug: "positive-energy-drink",
    title: "Positive Energy Drink",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.211Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["nerd-drinks", "energy drinks"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b1",
    children: "Energy Drinks",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.",
    image: images["product-75.png"],
    originalPrice: 8,
    parent: "Drinks",
    quantity: 5,
    slug: "nerd-energy-drink",
    title: "Nerd Energy Drink",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-30T15:18:26.172Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["monster-drinks", "energy drinks"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4b0",
    children: "Energy Drinks",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.",
    image: images["product-76.png"],

    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "monster-energy-drink",
    title: "Monster Energy Drink",
    type: "Grocery",
    unit: "15oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["bung-drinks", "energy drinks"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4af",
    children: "Energy Drinks",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.",
    image: images["product-77.png"],
    originalPrice: 13,
    parent: "Drinks",
    quantity: 100,
    slug: "bing-energy-drink",
    title: "BING Energy Drink",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["go-grid-drinks", "energy drinks"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ae",
    children: "Energy Drinks",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Energy drink, any beverage that contains high levels of a stimulant ingredient, usually caffeine, as well as sugar and often supplements, such as vitamins or carnitine, and that is promoted as a product capable of enhancing mental alertness and physical performance.",
    image: images["product-78.png"],

    originalPrice: 9,
    parent: "Drinks",
    quantity: 100,
    slug: "go-grid-energy-drink",
    title: "Go Grid Energy Drink",
    type: "Grocery",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["robinsons-juice", "juice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ad",
    children: "Juice",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.",
    image: images["product-79.png"],

    originalPrice: 11,
    parent: "Drinks",
    quantity: 100,
    slug: "robinsons-pink-lemonade",
    title: "Robinsons Pink Lemonade",
    type: "Grocery",
    unit: "10fl-oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["Lime-juice", "juice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ac",
    children: "Juice",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.",
    image: images["product-80.png"],

    originalPrice: 9,
    parent: "Drinks",
    quantity: 100,
    slug: "strawberry-lime-juice",
    title: "Strawberry Lime Juice",
    type: "Grocery",
    unit: "33fl-oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["musselman-juice", "juice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ab",
    children: "Juice",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.",
    image: images["product-81.png"],

    originalPrice: 10,
    parent: "Drinks",
    quantity: 100,
    slug: "musselman-sparkling-cider",
    title: "Musselman Sparkling Cider",
    type: "Grocery",
    unit: "750ml",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["orange-juice", "juice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4aa",
    children: "Juice",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.",
    image: images["product-82.png"],

    originalPrice: 11,
    parent: "Drinks",
    quantity: 100,
    slug: "hood-orange-juice",
    title: "Hood Orange Juice",
    type: "Grocery",
    unit: "14fl-oz",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["orange-juice", "juice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a9",
    children: "Juice",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Juice is a drink made from the extraction or pressing of the natural liquid contained in fruit and vegetables. It can also refer to liquids that are flavored with concentrate or other biological food sources, such as meat or seafood, such as clam juice.",
    image: images["product-83.png"],
    originalPrice: 10,
    parent: "Drinks",
    quantity: 100,
    slug: "evolution-orange-juice",
    title: "Evolution Orange Juice",
    type: "Grocery",
    unit: "325ml",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["vpk-organic-tea", "tea"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a8",
    children: "Tea",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Tea is an aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis, an evergreen shrub native to China and East Asia. After water, it is the most widely consumed drink in the world. ... Tea has a stimulating effect in humans primarily due to its caffeine content.",
    image: images["product-84.png"],
    originalPrice: 6,
    parent: "Drinks",
    quantity: 100,
    slug: "vpk-organic-kapha",
    title: "VPK Organic Kapha",
    type: "Grocery",
    unit: "16ct",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["herbal-tea", "tea"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a7",
    children: "Tea",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Tea is an aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis, an evergreen shrub native to China and East Asia. After water, it is the most widely consumed drink in the world. ... Tea has a stimulating effect in humans primarily due to its caffeine content.",
    image: images["product-85.png"],
    originalPrice: 5,
    parent: "Drinks",
    quantity: 100,
    slug: "calming-herbal-tea",
    title: "Calming Herbal Tea",
    type: "Grocery",
    unit: "15ct",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["slimming-s-tea", "tea"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a6",
    children: "Tea",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Tea is an aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis, an evergreen shrub native to China and East Asia. After water, it is the most widely consumed drink in the world. ... Tea has a stimulating effect in humans primarily due to its caffeine content.",
    image: images["product-86.png"],
    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "slimming-slender-tea",
    title: "Slimming Slender Tea",
    type: "Grocery",
    unit: "24ct",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["rose-herb-tea", "tea"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a5",
    children: "Tea",
    createdAt: "2021-12-22T16:41:23.210Z",
    description:
      "Tea is an aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis, an evergreen shrub native to China and East Asia. After water, it is the most widely consumed drink in the world. ... Tea has a stimulating effect in humans primarily due to its caffeine content.",
    image: images["product-87.png"],
    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "mt-rose-herb-tea",
    title: "Mt Rose Herb Tea",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.210Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["organic-tea", "tea"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a4",
    children: "Tea",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "Tea is an aromatic beverage prepared by pouring hot or boiling water over cured or fresh leaves of Camellia sinensis, an evergreen shrub native to China and East Asia. After water, it is the most widely consumed drink in the world. ... Tea has a stimulating effect in humans primarily due to its caffeine content.",
    image: images["product-88.png"],
    originalPrice: 8,
    parent: "Drinks",
    quantity: 100,
    slug: "organic-pitta-tea",
    title: "Organic Pitta Tea",
    type: "Grocery",
    unit: "16ct",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["alhambra-water", "water"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a3",
    children: "Water",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "water, a substance composed of the chemical elements hydrogen and oxygen and existing in gaseous, liquid, and solid states. It is one of the most plentiful and essential of compounds. A tasteless and odourless liquid at room temperature, it has the important ability to dissolve many other substances.",
    image: images["product-89.png"],
    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "alhambra-purified-water",
    title: "Alhambra Purified Water",
    type: "Grocery",
    unit: "1ltr",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["deer-park", "water"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a2",
    children: "Water",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "water, a substance composed of the chemical elements hydrogen and oxygen and existing in gaseous, liquid, and solid states. It is one of the most plentiful and essential of compounds. A tasteless and odourless liquid at room temperature, it has the important ability to dissolve many other substances.",
    image: images["product-90.png"],
    originalPrice: 7,
    parent: "Drinks",
    quantity: 100,
    slug: "deer-park-water",
    title: "Deer Park Water",
    type: "Grocery",
    unit: "700ml",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["food-club", "water"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a1",
    children: "Water",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "water, a substance composed of the chemical elements hydrogen and oxygen and existing in gaseous, liquid, and solid states. It is one of the most plentiful and essential of compounds. A tasteless and odourless liquid at room temperature, it has the important ability to dissolve many other substances.",
    image: images["product-91.png"],
    originalPrice: 8,
    parent: "Drinks",
    quantity: 8,
    slug: "food-club-water",
    title: "Food Club Water",
    type: "Grocery",
    unit: "1ltr",
    updatedAt: "2022-07-01T04:52:12.274Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["fresh-thyme", "water"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4a0",
    children: "Water",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "water, a substance composed of the chemical elements hydrogen and oxygen and existing in gaseous, liquid, and solid states. It is one of the most plentiful and essential of compounds. A tasteless and odourless liquid at room temperature, it has the important ability to dissolve many other substances.",
    image: images["product-92.png"],

    originalPrice: 6,
    parent: "Drinks",
    quantity: 100,
    slug: "fresh-thyme-water",
    title: "Fresh-Thyme Water",
    type: "Grocery",
    unit: "3ltr",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["smeraldina", "water"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49f",
    children: "Water",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "water, a substance composed of the chemical elements hydrogen and oxygen and existing in gaseous, liquid, and solid states. It is one of the most plentiful and essential of compounds. A tasteless and odourless liquid at room temperature, it has the important ability to dissolve many other substances.",
    image: images["product-93.png"],
    originalPrice: 5,
    parent: "Drinks",
    quantity: 100,
    slug: "smeraldina-water",
    title: "Smeraldina Water",
    type: "Grocery",
    unit: ".5ltr",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49e",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-94.png"],
    originalPrice: 12,
    parent: "Fresh Seafood",
    quantity: 100,
    slug: "wild-king-salmon-steak",
    title: "Wild King Salmon Steak",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49d",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-95.png"],
    originalPrice: 12,
    parent: "Fresh Seafood",
    quantity: 96,
    slug: "canada-salmon",
    title: "Canada Salmon",
    type: "Grocery",
    unit: "5oz",
    updatedAt: "2022-05-31T06:13:45.671Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49c",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-96.png"],
    originalPrice: 15,
    parent: "Fresh Seafood",
    quantity: 4,
    slug: "atlantic-salmon",
    title: "Atlantic Salmon",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-06T00:56:01.988Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["salmon", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49b",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-97.png"],
    originalPrice: 12,
    parent: "Fresh Seafood",
    quantity: 36,
    slug: "salmon-filet-portion",
    title: "Salmon Filet Portion",
    type: "Grocery",
    unit: "6oz",
    updatedAt: "2022-07-02T07:41:35.727Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["ling-cod", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af49a",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-98.png"],
    originalPrice: 11,
    parent: "Fresh Seafood",
    quantity: 57,
    slug: "ling-cod-steak",
    title: "Ling Cod Steak",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-20T08:24:40.974Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["shrimp", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af499",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-99.png"],
    originalPrice: 12,
    parent: "Fresh Seafood",
    quantity: 69,
    slug: "peeled-deveined-shrimp",
    title: "Peeled Deveined Shrimp",
    type: "Grocery",
    unit: "2lb",
    updatedAt: "2022-07-02T07:41:35.728Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["sea-bass", "fish"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af498",
    children: "Fresh Seafood",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "A fish is an animal which lives and breathes in water. All fish are vertebrates (have a backbone) and most breathe through gills and have fins and scales. ... There are three classes of fish: jawless, cartilaginous, and bony. All fish have a backbone.",
    image: images["product-100.png"],
    originalPrice: 14,
    parent: "Fresh Seafood",
    quantity: 49,
    slug: "chilean-sea-bass",
    title: "Chilean Sea Bass",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-31T06:13:45.672Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["aci sugar", "Spices & Mixes"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af497",
    children: "Spices & Mixes",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "Spice mixes are blended spices or herbs. ... Blends such as chili powder, curry powder, herbes de Provence, garlic salt, and other seasoned salts are traditionally sold pre-made by grocers, and sometimes baking blends such as pumpkin pie spice are also available.",
    image: images["product-101.png"],
    originalPrice: 12,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aci-pure-sugar",
    title: "ACI Pure Sugar",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["aci salt", "Spices & Mixes"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af496",
    children: "Spices & Mixes",
    createdAt: "2021-12-22T16:41:23.209Z",
    description:
      "Spice mixes are blended spices or herbs. ... Blends such as chili powder, curry powder, herbes de Provence, garlic salt, and other seasoned salts are traditionally sold pre-made by grocers, and sometimes baking blends such as pumpkin pie spice are also available.",
    image: images["product-102.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aci-pure-salt",
    title: "ACI Pure Salt",
    type: "Grocery",
    unit: "750gm",
    updatedAt: "2021-12-22T16:41:23.209Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["fresh flour", "flour"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af495",
    children: "Flour",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Flour, finely ground cereal grains or other starchy portions of plants, used in various food products and as a basic ingredient of baked goods. Flour made from wheat grains is the most satisfactory type for baked products that require spongy structure. ... The outer layers and internal structures of a kernel of wheat.",
    image: images["product-103.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "fresh-flour",
    title: "Fresh Flour",
    type: "Grocery",
    unit: "2kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["aci flour", "flour"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af494",
    children: "Flour",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Flour, finely ground cereal grains or other starchy portions of plants, used in various food products and as a basic ingredient of baked goods. Flour made from wheat grains is the most satisfactory type for baked products that require spongy structure. ... The outer layers and internal structures of a kernel of wheat.",
    image: images["product-104.png"],
    originalPrice: 9,
    parent: "Cooking Essentials",
    quantity: 69,
    slug: "aci-pure-flour",
    title: "ACI Pure Flour",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-07-15T13:51:15.358Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["aci flour", "flour"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af493",
    children: "Flour",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Flour, finely ground cereal grains or other starchy portions of plants, used in various food products and as a basic ingredient of baked goods. Flour made from wheat grains is the most satisfactory type for baked products that require spongy structure. ... The outer layers and internal structures of a kernel of wheat.",
    image: images["product-105.png"],
    originalPrice: 11,
    parent: "Cooking Essentials",
    quantity: 11,
    slug: "aci-pure-flour",
    title: "ACI Pure Flour",
    type: "Grocery",
    unit: "2kg",
    updatedAt: "2021-12-30T15:24:29.683Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["aci flour", "flour"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af492",
    children: "Flour",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Flour, finely ground cereal grains or other starchy portions of plants, used in various food products and as a basic ingredient of baked goods. Flour made from wheat grains is the most satisfactory type for baked products that require spongy structure. ... The outer layers and internal structures of a kernel of wheat.",
    image: images["product-106.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 99,
    slug: "aci-nutrilife-oats-flour",
    title: "ACI Pure Flour",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-07-15T13:51:15.359Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["aci flour", "flour"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af491",
    children: "Flour",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Flour, finely ground cereal grains or other starchy portions of plants, used in various food products and as a basic ingredient of baked goods. Flour made from wheat grains is the most satisfactory type for baked products that require spongy structure. ... The outer layers and internal structures of a kernel of wheat.",
    image: images["product-107.png"],
    originalPrice: 12,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aci-nutrilife-flour",
    title: "ACI Nutrilife Flour",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["fresh oil", "oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af490",
    children: "Oil",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. ... Cooking oil is typically a liquid at room temperature, although some oils that contain saturated fat, such as coconut oil, palm oil and palm kernel oil are solid.",
    image: images["product-108.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "fresh-soyabean-oil",
    title: "Fresh Soyabean Oil",
    type: "Grocery",
    unit: "5ltr",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["fresh oil", "oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48f",
    children: "Oil",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. ... Cooking oil is typically a liquid at room temperature, although some oils that contain saturated fat, such as coconut oil, palm oil and palm kernel oil are solid.",
    image: images["product-109.png"],
    originalPrice: 11,
    parent: "Cooking Essentials",
    quantity: 55,
    slug: "fresh-mustard-oil",
    title: "Fresh Mustard Oil",
    type: "Grocery",
    unit: "500ml",
    updatedAt: "2021-12-30T15:24:17.942Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["fortune oil", "oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48e",
    children: "Oil",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. ... Cooking oil is typically a liquid at room temperature, although some oils that contain saturated fat, such as coconut oil, palm oil and palm kernel oil are solid.",
    image: images["product-110.png"],
    originalPrice: 8,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "fortune-oil",
    title: "Fortune Oil",
    type: "Grocery",
    unit: "5ltr",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["aci oil", "oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48d",
    children: "Oil",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. ... Cooking oil is typically a liquid at room temperature, although some oils that contain saturated fat, such as coconut oil, palm oil and palm kernel oil are solid.",
    image: images["product-111.png"],
    originalPrice: 9,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aci-pure-mustard-oil",
    title: "ACI Pure Mustard Oil",
    type: "Grocery",
    unit: "200ml",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["aci oil", "oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48c",
    children: "Oil",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Cooking oil is plant, animal, or synthetic fat used in frying, baking, and other types of cooking. ... Cooking oil is typically a liquid at room temperature, although some oils that contain saturated fat, such as coconut oil, palm oil and palm kernel oil are solid.",
    image: images["product-112.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aci-aroma-mustard-oil",
    title: "ACI Aroma Mustard Oil",
    type: "Grocery",
    unit: "1ltr",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["kalijira rice", "rice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48b",
    children: "Rice",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "rice, (Oryza sativa), edible starchy cereal grain and the grass plant (family Poaceae) by which it is produced. ... Rice is cooked by boiling, or it can be ground into a flour. It is eaten alone and in a great variety of soups, side dishes, and main dishes in Asian, Middle Eastern, and many other cuisines.",
    image: images["product-113.png"],
    originalPrice: 12,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "kalijira-premium-rice",
    title: "Kalijira Premium Rice",
    type: "Grocery",
    unit: "5kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["chinigura rice", "rice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af48a",
    children: "Rice",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "rice, (Oryza sativa), edible starchy cereal grain and the grass plant (family Poaceae) by which it is produced. ... Rice is cooked by boiling, or it can be ground into a flour. It is eaten alone and in a great variety of soups, side dishes, and main dishes in Asian, Middle Eastern, and many other cuisines.",
    image: images["product-114.png"],
    originalPrice: 14,
    parent: "Cooking Essentials",
    quantity: 5,
    slug: "fresh-chinigura-rice",
    title: "Fresh Chinigura Rice",
    type: "Grocery",
    unit: "5kg",
    updatedAt: "2021-12-30T15:22:58.653Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["chinigura rice", "rice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af489",
    children: "Rice",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "rice, (Oryza sativa), edible starchy cereal grain and the grass plant (family Poaceae) by which it is produced. ... Rice is cooked by boiling, or it can be ground into a flour. It is eaten alone and in a great variety of soups, side dishes, and main dishes in Asian, Middle Eastern, and many other cuisines.",
    image: images["product-115.png"],
    originalPrice: 15,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "chinigura-premium-rice",
    title: "Chinigura Premium Rice",
    type: "Grocery",
    unit: "5kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["aarong rice", "rice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af488",
    children: "Rice",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "rice, (Oryza sativa), edible starchy cereal grain and the grass plant (family Poaceae) by which it is produced. ... Rice is cooked by boiling, or it can be ground into a flour. It is eaten alone and in a great variety of soups, side dishes, and main dishes in Asian, Middle Eastern, and many other cuisines.",
    image: images["product-116.png"],
    originalPrice: 13,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aarong-nazirshail-rice",
    title: "Aarong Nazirshail Rice",
    type: "Grocery",
    unit: "5kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["aarong rice", "rice"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af487",
    children: "Rice",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "rice, (Oryza sativa), edible starchy cereal grain and the grass plant (family Poaceae) by which it is produced. ... Rice is cooked by boiling, or it can be ground into a flour. It is eaten alone and in a great variety of soups, side dishes, and main dishes in Asian, Middle Eastern, and many other cuisines.",
    image: images["product-117.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "aarong-kataribhog-rice",
    title: "Aarong Kataribhog Rice",
    type: "Grocery",
    unit: "5kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["onion", "dry vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af486",
    children: "Dry Vegetable",
    createdAt: "2021-12-22T16:41:23.208Z",
    description:
      "Any vegetable that has been dehydrated or dried in order to extend the shelf life and to concentrate the flavor. Popular dried vegetables include sun-dried tomatoes, onions, carrots, and chiles.",
    image: images["product-118.png"],
    originalPrice: 9,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "onion-loose",
    title: "Onion Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2021-12-22T16:41:23.208Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["ginger hybrid", "dry vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af485",
    children: "Dry Vegetable",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Any vegetable that has been dehydrated or dried in order to extend the shelf life and to concentrate the flavor. Popular dried vegetables include sun-dried tomatoes, onions, carrots, and chiles.",
    image: images["product-119.png"],
    originalPrice: 12,
    parent: "Cooking Essentials",
    quantity: 99,
    slug: "ginger-hybrid",
    title: "Ginger Hybrid",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-05-21T18:37:12.762Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["garlic", "dry vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af484",
    children: "Dry Vegetable",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Any vegetable that has been dehydrated or dried in order to extend the shelf life and to concentrate the flavor. Popular dried vegetables include sun-dried tomatoes, onions, carrots, and chiles.",
    image: images["product-120.png"],
    originalPrice: 10,
    parent: "Cooking Essentials",
    quantity: 149,
    slug: "garlic-loose",
    title: "Garlic Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-05-21T18:37:12.762Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["garlic", "dry vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af483",
    children: "Dry Vegetable",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Any vegetable that has been dehydrated or dried in order to extend the shelf life and to concentrate the flavor. Popular dried vegetables include sun-dried tomatoes, onions, carrots, and chiles.",
    image: images["product-121.png"],
    originalPrice: 13,
    parent: "Cooking Essentials",
    quantity: 100,
    slug: "ginger-imported-loose",
    title: "Ginger Imported Loose",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cake", "dan-cake"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af482",
    children: "Cakes",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Cake is a form of sweet food made from flour, sugar, and other ingredients, that is usually baked. ... The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and a leavening agent, such as baking soda or baking powder.",
    image: images["product-122.png"],
    originalPrice: 8,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "dan-cake-layer-cake",
    title: "Dan Cake Layer Cake",
    type: "Grocery",
    unit: "1box",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["cake", "dan-cake"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af481",
    children: "Cakes",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Cake is a form of sweet food made from flour, sugar, and other ingredients, that is usually baked. ... The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and a leavening agent, such as baking soda or baking powder.",
    image: images["product-123.png"],
    originalPrice: 13,
    parent: "Biscuits & Cakes",
    quantity: 8,
    slug: "dan-cake-lemon",
    title: "Dan Cake Lemon",
    type: "Grocery",
    unit: "1box",
    updatedAt: "2022-07-15T19:26:14.501Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["cake", "dan-cake"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af480",
    children: "Cakes",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Cake is a form of sweet food made from flour, sugar, and other ingredients, that is usually baked. ... The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and a leavening agent, such as baking soda or baking powder.",
    image: images["product-124.png"],
    originalPrice: 9,
    parent: "Biscuits & Cakes",
    quantity: 96,
    slug: "dan-cake-pound-cake",
    title: "Dan Cake Pound Cake",
    type: "Grocery",
    unit: "1box",
    updatedAt: "2022-07-16T10:49:11.083Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["cake", "dan-cake"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47f",
    children: "Cakes",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Cake is a form of sweet food made from flour, sugar, and other ingredients, that is usually baked. ... The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and a leavening agent, such as baking soda or baking powder.",
    image: images["product-125.png"],
    originalPrice: 10,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "dan-cake-chocolate-muffin",
    title: "Dan Cake Chocolate Muffin",
    type: "Grocery",
    unit: "1box",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cake", "dan-cake"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47e",
    children: "Cakes",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "Cake is a form of sweet food made from flour, sugar, and other ingredients, that is usually baked. ... The most commonly used cake ingredients include flour, sugar, eggs, butter or oil or margarine, a liquid, and a leavening agent, such as baking soda or baking powder.",
    image: images["product-126.png"],
    originalPrice: 11,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "dan-cake",
    title: "Dan Cake",
    type: "Grocery",
    unit: "1box",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["biscuit", "dekko ovaltine-cookie"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47d",
    children: "Biscuits",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "A biscuit is a flour-based baked food product. In most countries, particularly in the Commonwealth and Ireland, biscuits are typically hard, flat and unleavened. They are usually sweet and may be made with sugar, chocolate, icing, jam, ginger or cinnamon. They can also be savoury and similar to crackers.",
    image: images["product-126.png"],
    originalPrice: 9,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "dekko-ovaltine-cookie",
    title: "Dekko Ovaltine Cookie",
    type: "Grocery",
    unit: "1pac",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["biscuit", "danish biscuit"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47c",
    children: "Biscuits",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "A biscuit is a flour-based baked food product. In most countries, particularly in the Commonwealth and Ireland, biscuits are typically hard, flat and unleavened. They are usually sweet and may be made with sugar, chocolate, icing, jam, ginger or cinnamon. They can also be savoury and similar to crackers.",
    image: images["product-127.png"],
    originalPrice: 8,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "danish-orange-biscuit",
    title: "Danish Orange Biscuit",
    type: "Grocery",
    unit: "1pac",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["biscuit", "bisk club"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47b",
    children: "Biscuits",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "A biscuit is a flour-based baked food product. In most countries, particularly in the Commonwealth and Ireland, biscuits are typically hard, flat and unleavened. They are usually sweet and may be made with sugar, chocolate, icing, jam, ginger or cinnamon. They can also be savoury and similar to crackers.",
    image: images["product-128.png"],
    originalPrice: 11,
    parent: "Biscuits & Cakes",
    quantity: 100,
    slug: "bisk-club-cream-biscuit",
    title: "Bisk Club Cream Biscuit",
    type: "Grocery",
    unit: "1pac",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["biscuit", "masala-flavor"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af47a",
    children: "Biscuits",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "A biscuit is a flour-based baked food product. In most countries, particularly in the Commonwealth and Ireland, biscuits are typically hard, flat and unleavened. They are usually sweet and may be made with sugar, chocolate, icing, jam, ginger or cinnamon. They can also be savoury and similar to crackers.",
    image: images["product-129.png"],
    originalPrice: 13,
    parent: "Biscuits & Cakes",
    quantity: 140,
    slug: "bisk-club-masala-flavor",
    title: "Bisk Club Masala Flavor",
    type: "Grocery",
    unit: "1pac",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["biscuit", "fun milk"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af479",
    children: "Biscuits",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "A biscuit is a flour-based baked food product. In most countries, particularly in the Commonwealth and Ireland, biscuits are typically hard, flat and unleavened. They are usually sweet and may be made with sugar, chocolate, icing, jam, ginger or cinnamon. They can also be savoury and similar to crackers.",
    image: images["product-130.png"],
    originalPrice: 10,
    parent: "Biscuits & Cakes",
    quantity: 8,
    slug: "fun-milk-wafer-biscuit",
    title: "Fun Milk Wafer Biscuit",
    type: "Grocery",
    unit: "1pac",
    updatedAt: "2021-12-30T15:22:33.284Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["sauces", "tomato sauce"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af478",
    children: "Sauces",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "sauce, liquid or semiliquid mixture that is added to a food as it cooks or that is served with it. Sauces provide flavour, moisture, and a contrast in texture and colour. They may also serve as a medium in which food is contained, for example, the velouté sauce of creamed chicken.",
    image: images["product-131.png"],
    originalPrice: 10,
    parent: "Sauces & Pickles",
    quantity: 50,
    slug: "life-tomato-ketchup",
    title: "Life Tomato Ketchup",
    type: "Grocery",
    unit: "330gm",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["sauces", "soya sauce"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af477",
    children: "Sauces",
    createdAt: "2021-12-22T16:41:23.207Z",
    description:
      "sauce, liquid or semiliquid mixture that is added to a food as it cooks or that is served with it. Sauces provide flavour, moisture, and a contrast in texture and colour. They may also serve as a medium in which food is contained, for example, the velouté sauce of creamed chicken.",
    image: images["product-132.png"],
    originalPrice: 11,
    parent: "Sauces & Pickles",
    quantity: 50,
    slug: "life-soya-sauce",
    title: "Life Soya Sauce",
    type: "Grocery",
    unit: "210ml",
    updatedAt: "2021-12-22T16:41:23.207Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["sauces", "soya sauce"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af476",
    children: "Sauces",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "sauce, liquid or semiliquid mixture that is added to a food as it cooks or that is served with it. Sauces provide flavour, moisture, and a contrast in texture and colour. They may also serve as a medium in which food is contained, for example, the velouté sauce of creamed chicken.",
    image: images["product-133.png"],
    originalPrice: 11,
    parent: "Sauces & Pickles",
    quantity: 79,
    slug: "life-dark-soya-sauce",
    title: "Life Dark Soya Sauce",
    type: "Grocery",
    unit: "210ml",
    updatedAt: "2022-06-06T04:19:45.982Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["sauces", "soya sauce"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af475",
    children: "Sauces",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "sauce, liquid or semiliquid mixture that is added to a food as it cooks or that is served with it. Sauces provide flavour, moisture, and a contrast in texture and colour. They may also serve as a medium in which food is contained, for example, the velouté sauce of creamed chicken.",
    image: images["product-134.png"],
    originalPrice: 10,
    parent: "Sauces & Pickles",
    quantity: 70,
    slug: "kikkoman-soya-sauce",
    title: "Kikkoman Soya Sauce",
    type: "Grocery",
    unit: "150ml",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["sauces", "calypso sauce"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af474",
    children: "Sauces",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "sauce, liquid or semiliquid mixture that is added to a food as it cooks or that is served with it. Sauces provide flavour, moisture, and a contrast in texture and colour. They may also serve as a medium in which food is contained, for example, the velouté sauce of creamed chicken.",
    image: images["product-135.png"],
    originalPrice: 12,
    parent: "Sauces & Pickles",
    quantity: 40,
    slug: "calypso-sauce",
    title: "Calypso Sauce",
    type: "Grocery",
    unit: "200ml",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["pickles", "Heinz vinegar"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af473",
    children: "Pickles & Condiments",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "The acetic acid in vinegar or the lactic acid produced by brine gives the pickle its salty, sour taste. ... Bread-and-butter pickles, for example, have a distinctly sweet taste because sugar and other sweeteners are added to the brine. Like tomatoes, cucumbers (and thus, pickles) are technically fruits.",
    image: images["product-136.png"],
    originalPrice: 14,
    parent: "Sauces & Pickles",
    quantity: 30,
    slug: "heinz-apple-cider-vinegar",
    title: "Heinz Apple Cider Vinegar",
    type: "Grocery",
    unit: "473ml",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["pickles", "discovery vinegar"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af472",
    children: "Pickles & Condiments",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "The acetic acid in vinegar or the lactic acid produced by brine gives the pickle its salty, sour taste. ... Bread-and-butter pickles, for example, have a distinctly sweet taste because sugar and other sweeteners are added to the brine. Like tomatoes, cucumbers (and thus, pickles) are technically fruits.",
    image: images["product-137.png"],
    originalPrice: 13,
    parent: "Sauces & Pickles",
    quantity: 30,
    slug: "discovery-apple-cider-vinegar",
    title: "Discovery Apple Cider Vinegar",
    type: "Grocery",
    unit: "473ml",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },

  {
    price: 12,
    discount: 0,
    tag: ["pickles", "american vinegar"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af470",
    children: "Pickles & Condiments",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "The acetic acid in vinegar or the lactic acid produced by brine gives the pickle its salty, sour taste. ... Bread-and-butter pickles, for example, have a distinctly sweet taste because sugar and other sweeteners are added to the brine. Like tomatoes, cucumbers (and thus, pickles) are technically fruits.",
    image: images["product-138.png"],
    originalPrice: 12,
    parent: "Sauces & Pickles",
    quantity: 70,
    slug: "american-apple-cider-vinegar",
    title: "American Apple Cider Vinegar",
    type: "Grocery",
    unit: "473g",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["pickles", "ahmed pickle"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46f",
    children: "Pickles & Condiments",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      "The acetic acid in vinegar or the lactic acid produced by brine gives the pickle its salty, sour taste. ... Bread-and-butter pickles, for example, have a distinctly sweet taste because sugar and other sweeteners are added to the brine. Like tomatoes, cucumbers (and thus, pickles) are technically fruits.",
    image: images["product-139.png"],
    originalPrice: 13,
    parent: "Sauces & Pickles",
    quantity: 70,
    slug: "ahmed-mango-pickle",
    title: "Ahmed Mango Pickle",
    type: "Grocery",
    unit: "400g",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["breakfast", "pita-bread"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46e",
    children: "Bread",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-140.png"],
    originalPrice: 10,
    parent: "Breakfast",
    quantity: 50,
    slug: "pita-bread",
    title: "Pita Bread",
    type: "Grocery",
    unit: "1pc",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["breakfast", "bread"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46d",
    children: "Bread",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-141.png"],
    originalPrice: 9,
    parent: "Breakfast",
    quantity: 39,
    slug: "bread",
    title: "Bread",
    type: "Grocery",
    unit: "1pc",
    updatedAt: "2022-07-15T19:26:14.502Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["breakfast", "pita-bread"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46c",
    children: "Bread",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-142.png"],
    originalPrice: 10,
    parent: "Breakfast",
    quantity: 90,
    slug: "pita-bread",
    title: "Pita Bread",
    type: "Grocery",
    unit: "1pc",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["breakfast", "wholemeal-bread"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46b",
    children: "Bread",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-143.png"],
    originalPrice: 11,
    parent: "Breakfast",
    quantity: 86,
    slug: "wholemeal-bread",
    title: "Wholemeal Bread",
    type: "Grocery",
    unit: "1pc",
    updatedAt: "2022-06-18T08:46:05.359Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["breakfast", "bread"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af46a",
    children: "Bread",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-144.png"],
    originalPrice: 7,
    parent: "Breakfast",
    quantity: 40,
    slug: "bread",
    title: "Bread",
    type: "Grocery",
    unit: "1pc",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["breakfast", "quaker-oats"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af469",
    children: "Cereal",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-145.png"],
    originalPrice: 11,
    parent: "Breakfast",
    quantity: 40,
    slug: "quaker-oats-jar",
    title: "Quaker Oats Jar",
    type: "Grocery",
    unit: "500gm",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["breakfast", "breakfast-cereal-pouch"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af468",
    children: "Cereal",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-146.png"],
    originalPrice: 5,
    parent: "Breakfast",
    quantity: 60,
    slug: "breakfast-cereal-pouch",
    title: "Breakfast Cereal Pouch",
    type: "Grocery",
    unit: "80gm",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["breakfast", "honey-breakfast-cereal"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af467",
    children: "Cereal",
    createdAt: "2021-12-22T16:41:23.206Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-147.png"],
    originalPrice: 10,
    parent: "Breakfast",
    quantity: 60,
    slug: "honey-breakfast-cereal",
    title: "Honey Breakfast Cereal",
    type: "Grocery",
    unit: "300gm",
    updatedAt: "2021-12-22T16:41:23.206Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["breakfast", "chocolate-breakfast-cereal"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af466",
    children: "Cereal",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-148.png"],
    originalPrice: 5,
    parent: "Breakfast",
    quantity: 60,
    slug: "chocolate-breakfast-cereal",
    title: "Chocolate Breakfast Cereal",
    type: "Grocery",
    unit: "17gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["breakfast", "flakes-breakfast-cereal"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af465",
    children: "Cereal",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      " The definition of a breakfast food is a food that is eaten primarily for the first meal of the day commonly including: cereal, toast, eggs, pancakes, waffles, pastries, sausage or bacon. An example of a breakfast food is oatmeal. An example of a breakfast food is french toast with scrambled eggs.",
    image: images["product-149.png"],
    originalPrice: 7,
    parent: "Breakfast",
    quantity: 60,
    slug: "flakes-breakfast-cereal",
    title: "Flakes Breakfast Cereal",
    type: "Grocery",
    unit: "275gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["aseel-vegetable", "ghee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af464",
    children: "Butter & Ghee",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "Butter, a yellow-to-white solid emulsion of fat globules, water, and inorganic salts produced by churning the cream from cows' milk. ... Butter is a high-energy food, containing approximately 715 calories per 100 grams. It has a high content of butterfat, or milk fat (at least 80 percent), but is low in protein.",
    image: images["product-150.png"],
    originalPrice: 9,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "aseel-vegetable-ghee",
    title: "Aseel Vegetable Ghee",
    type: "Grocery",
    unit: "500gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["ultra", "ghee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af463",
    children: "Butter & Ghee",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "Butter, a yellow-to-white solid emulsion of fat globules, water, and inorganic salts produced by churning the cream from cows' milk. ... Butter is a high-energy food, containing approximately 715 calories per 100 grams. It has a high content of butterfat, or milk fat (at least 80 percent), but is low in protein.",
    image: images["product-151.png"],
    originalPrice: 10,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "ultra-ghee",
    title: "Ultra Ghee",
    type: "Grocery",
    unit: "200gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["pran-premium", "ghee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af462",
    children: "Butter & Ghee",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "Butter, a yellow-to-white solid emulsion of fat globules, water, and inorganic salts produced by churning the cream from cows' milk. ... Butter is a high-energy food, containing approximately 715 calories per 100 grams. It has a high content of butterfat, or milk fat (at least 80 percent), but is low in protein.",
    image: images["product-152.png"],
    originalPrice: 13,
    parent: "Milk & Dairy",
    quantity: 7,
    slug: "pran-premium-ghee",
    title: "Pran Premium Ghee",
    type: "Grocery",
    unit: "200gm",
    updatedAt: "2021-12-30T15:22:05.707Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["aarong", "ghee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af461",
    children: "Butter & Ghee",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "Butter, a yellow-to-white solid emulsion of fat globules, water, and inorganic salts produced by churning the cream from cows' milk. ... Butter is a high-energy food, containing approximately 715 calories per 100 grams. It has a high content of butterfat, or milk fat (at least 80 percent), but is low in protein.",
    image: images["product-153.png"],
    originalPrice: 10,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "aarong-ghee",
    title: "Aarong Ghee",
    type: "Grocery",
    unit: "200gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["aarong", "ghee"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af460",
    children: "Butter & Ghee",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "Butter, a yellow-to-white solid emulsion of fat globules, water, and inorganic salts produced by churning the cream from cows' milk. ... Butter is a high-energy food, containing approximately 715 calories per 100 grams. It has a high content of butterfat, or milk fat (at least 80 percent), but is low in protein.",
    image: images["product-154.png"],
    originalPrice: 14,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "aarong-dairy-ghee",
    title: "Aarong Dairy Ghee",
    type: "Grocery",
    unit: "100gm",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["shurfine-milk", "dairy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45f",
    children: "Dairy",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "a room, building, or establishment where milk is kept and butter or cheese is made. 2a : the department of farming or of a farm that is concerned with the production of milk, butter, and cheese. b : a farm devoted to such production.",
    image: images["product-155.png"],
    originalPrice: 13,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "shurfine-whole-milk",
    title: "Shurfine Whole Milk",
    type: "Grocery",
    unit: "5gal",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["shurfine-yogurt", "dairy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45e",
    children: "Dairy",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "a room, building, or establishment where milk is kept and butter or cheese is made. 2a : the department of farming or of a farm that is concerned with the production of milk, butter, and cheese. b : a farm devoted to such production.",
    image: images["product-156.png"],
    originalPrice: 11,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "shurfine-vanilla-yogurt",
    title: "Shurfine Vanilla Yogurt",
    type: "Grocery",
    unit: "60oz",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["mexicano-yogurt", "dairy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45d",
    children: "Dairy",
    createdAt: "2021-12-22T16:41:23.205Z",
    description:
      "a room, building, or establishment where milk is kept and butter or cheese is made. 2a : the department of farming or of a farm that is concerned with the production of milk, butter, and cheese. b : a farm devoted to such production.",
    image: images["product-157.png"],
    originalPrice: 9,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "el-mexicano-yogurt",
    title: "El Mexicano Yogurt",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.205Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cowgirl-creamery", "dairy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45c",
    children: "Dairy",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "a room, building, or establishment where milk is kept and butter or cheese is made. 2a : the department of farming or of a farm that is concerned with the production of milk, butter, and cheese. b : a farm devoted to such production.",
    image: images["product-158.png"],
    originalPrice: 11,
    parent: "Milk & Dairy",
    quantity: 10,
    slug: "cowgirl-creamery",
    title: "Cowgirl Creamery",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-30T15:21:53.083Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["balducci", "dairy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45b",
    children: "Dairy",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "a room, building, or establishment where milk is kept and butter or cheese is made. 2a : the department of farming or of a farm that is concerned with the production of milk, butter, and cheese. b : a farm devoted to such production.",
    image: images["product-159.png"],

    originalPrice: 10,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "balducci-australian-cheddar",
    title: "Balducci AUSTRALIAN CHEDDAR",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2021-12-22T16:41:23.204Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["polar", "ice cream"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af45a",
    children: "Ice Cream",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "Ice cream is a frozen dairy dessert obtained by freezing the ice cream mix with continuous agitation. It contains milk products, sweetening materials, stabilizers, colors, flavors, and egg products. Ice cream had its origins in Europe and was introduced later in the United States where it developed into an industry.",
    image: images["product-160.png"],
    originalPrice: 8,
    parent: "Milk & Dairy",
    quantity: 99,
    slug: "polar",
    title: "Polar",
    type: "Grocery",
    unit: "1ltr",
    updatedAt: "2022-07-15T19:26:14.502Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["igloo", "ice cream"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af459",
    children: "Ice Cream",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "Ice cream is a frozen dairy dessert obtained by freezing the ice cream mix with continuous agitation. It contains milk products, sweetening materials, stabilizers, colors, flavors, and egg products. Ice cream had its origins in Europe and was introduced later in the United States where it developed into an industry.",
    image: images["product-161.png"],
    originalPrice: 10,
    parent: "Milk & Dairy",
    quantity: 7,
    slug: "igloo-kheer-malai",
    title: "Igloo Kheer Malai",
    type: "Grocery",
    unit: "1ltr",
    updatedAt: "2021-12-30T15:21:25.566Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["igloo", "ice cream"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af458",
    children: "Ice Cream",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "Ice cream is a frozen dairy dessert obtained by freezing the ice cream mix with continuous agitation. It contains milk products, sweetening materials, stabilizers, colors, flavors, and egg products. Ice cream had its origins in Europe and was introduced later in the United States where it developed into an industry.",
    image: images["product-162.png"],
    originalPrice: 9,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "igloo-cup-vanilla",
    title: "Igloo Cup Vanilla",
    type: "Grocery",
    unit: "100ml",
    updatedAt: "2021-12-22T16:41:23.204Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["igloo", "ice cream"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af457",
    children: "Ice Cream",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "Ice cream is a frozen dairy dessert obtained by freezing the ice cream mix with continuous agitation. It contains milk products, sweetening materials, stabilizers, colors, flavors, and egg products. Ice cream had its origins in Europe and was introduced later in the United States where it developed into an industry.",
    image: images["product-163.png"],
    originalPrice: 11,
    parent: "Milk & Dairy",
    quantity: 5,
    slug: "igloo-exotic-swiss",
    title: "Igloo Exotic Swiss",
    type: "Grocery",
    unit: "100ml",
    updatedAt: "2021-12-30T15:21:11.072Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["igloo", "ice cream"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af456",
    children: "Ice Cream",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "Ice cream is a frozen dairy dessert obtained by freezing the ice cream mix with continuous agitation. It contains milk products, sweetening materials, stabilizers, colors, flavors, and egg products. Ice cream had its origins in Europe and was introduced later in the United States where it developed into an industry.",
    image: images["product-164.png"],
    originalPrice: 9,
    parent: "Milk & Dairy",
    quantity: 100,
    slug: "igloo-container-vanilla",
    title: "Igloo Container Vanilla",
    type: "Grocery",
    unit: "1000ml",
    updatedAt: "2021-12-22T16:41:23.204Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["air freshener"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af455",
    children: "Air Freshener",
    createdAt: "2021-12-22T16:41:23.204Z",
    description:
      "a device used to disperse chemicals intended to mask or eliminate unpleasant odors The bathrooms should also have matching towels, soap and tissue dispensers, and a candle or plug-in air freshener with a pleasant scent.",
    image: images["product-165.png"],
    originalPrice: 8,
    parent: "Household Tools",
    quantity: 99,
    slug: "lavender-orange-blossom",
    title: "Lavender Orange Blossom",
    type: "Grocery",
    unit: "7oz",
    updatedAt: "2022-06-17T03:23:17.216Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["air freshener"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af454",
    children: "Air Freshener",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "a device used to disperse chemicals intended to mask or eliminate unpleasant odors The bathrooms should also have matching towels, soap and tissue dispensers, and a candle or plug-in air freshener with a pleasant scent.",
    image: images["product-166.png"],
    originalPrice: 6,
    parent: "Household Tools",
    quantity: 100,
    slug: "glade-automatic-spray",
    title: "Glade Automatic Spray",
    type: "Grocery",
    unit: "4oz",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["air freshener"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af453",
    children: "Air Freshener",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "a device used to disperse chemicals intended to mask or eliminate unpleasant odors The bathrooms should also have matching towels, soap and tissue dispensers, and a candle or plug-in air freshener with a pleasant scent.",
    image: images["product-167.png"],
    originalPrice: 7,
    parent: "Household Tools",
    quantity: 8,
    slug: "natures-alchemy-rosewood",
    title: "Natures Alchemy Rosewood",
    type: "Grocery",
    unit: "5oz",
    updatedAt: "2021-12-30T15:20:56.216Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["air freshener"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af452",
    children: "Air Freshener",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "a device used to disperse chemicals intended to mask or eliminate unpleasant odors The bathrooms should also have matching towels, soap and tissue dispensers, and a candle or plug-in air freshener with a pleasant scent.",
    image: images["product-168.png"],
    originalPrice: 5,
    parent: "Household Tools",
    quantity: 100,
    slug: "glade-air-freshener",
    title: "Glade Air Freshener",
    type: "Grocery",
    unit: "2oz",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["air freshener"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af451",
    children: "Air Freshener",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "a device used to disperse chemicals intended to mask or eliminate unpleasant odors The bathrooms should also have matching towels, soap and tissue dispensers, and a candle or plug-in air freshener with a pleasant scent.",
    image: images["product-169.png"],
    originalPrice: 9,
    parent: "Household Tools",
    quantity: 100,
    slug: "glade-refills-scented",
    title: "Glade Refills Scented",
    type: "Grocery",
    unit: "7oz",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["cleaner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af450",
    children: "Cleaner",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "A Cleaner, or Custodian, is responsible for keeping offices, homes, hotels or other public areas neat and organized. Their main duties include sweeping, mopping and vacuuming floors, dusting countertops, ceilings and furniture and sanitizing bathrooms, kitchens or other public areas.",
    image: images["product-170.png"],
    originalPrice: 10,
    parent: "Household Tools",
    quantity: 100,
    slug: "mr-clean-cleaner",
    title: "Mr Clean Cleaner",
    type: "Grocery",
    unit: "40oz",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["cleaner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44f",
    children: "Cleaner",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "A Cleaner, or Custodian, is responsible for keeping offices, homes, hotels or other public areas neat and organized. Their main duties include sweeping, mopping and vacuuming floors, dusting countertops, ceilings and furniture and sanitizing bathrooms, kitchens or other public areas.",
    image: images["product-171.png"],
    originalPrice: 9,
    parent: "Household Tools",
    quantity: 140,
    slug: "good-clean-wipes",
    title: "Good Clean Wipes",
    type: "Grocery",
    unit: "24ct",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cleaner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44e",
    children: "Cleaner",
    createdAt: "2021-12-22T16:41:23.203Z",
    description:
      "A Cleaner, or Custodian, is responsible for keeping offices, homes, hotels or other public areas neat and organized. Their main duties include sweeping, mopping and vacuuming floors, dusting countertops, ceilings and furniture and sanitizing bathrooms, kitchens or other public areas.",
    image: images["product-172.png"],
    originalPrice: 8,
    parent: "Household Tools",
    quantity: 100,
    slug: "clean-it-cleaner",
    title: "Clean It Cleaner",
    type: "Grocery",
    unit: "32oz",
    updatedAt: "2021-12-22T16:41:23.203Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["cleaner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44d",
    children: "Cleaner",
    createdAt: "2021-12-22T16:41:23.202Z",
    description:
      "A Cleaner, or Custodian, is responsible for keeping offices, homes, hotels or other public areas neat and organized. Their main duties include sweeping, mopping and vacuuming floors, dusting countertops, ceilings and furniture and sanitizing bathrooms, kitchens or other public areas.",
    image: images["product-173.png"],
    originalPrice: 7,
    parent: "Household Tools",
    quantity: 100,
    slug: "artik-madarine-cleaner",
    title: "Artik Mandarine Cleaner",
    type: "Grocery",
    unit: "8oz",
    updatedAt: "2021-12-22T16:41:23.202Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cleaner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44c",
    children: "Cleaner",
    createdAt: "2021-12-22T16:41:23.202Z",
    description:
      "A Cleaner, or Custodian, is responsible for keeping offices, homes, hotels or other public areas neat and organized. Their main duties include sweeping, mopping and vacuuming floors, dusting countertops, ceilings and furniture and sanitizing bathrooms, kitchens or other public areas.",
    image: images["product-174.png"],
    originalPrice: 8,
    parent: "Household Tools",
    quantity: 73,
    slug: "drano-build-up-remover",
    title: "Drano Build Up Remover",
    type: "Grocery",
    unit: "32oz",
    updatedAt: "2022-06-09T11:41:56.875Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cleaning tools"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44b",
    children: "Cleaning Tools",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A variety of accurate and specific tools and products created and designed in various colors, materials, mechanisms, shapes, sizes and styles to clean easily, effectively and efficiently. Cleaning tools are vital to clean especially when your form of employment involves cleaning.",
    image: images["product-175.png"],
    originalPrice: 11,
    parent: "Household Tools",
    quantity: 30,
    slug: "window-squeegee",
    title: "Window Squeegee",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-30T15:20:45.463Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["cleaning tools"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af44a",
    children: "Cleaning Tools",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A variety of accurate and specific tools and products created and designed in various colors, materials, mechanisms, shapes, sizes and styles to clean easily, effectively and efficiently. Cleaning tools are vital to clean especially when your form of employment involves cleaning.",
    image: images["product-176.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "scubbing-pad-brush",
    title: "Scubbing Pad Brush",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cleaning tools"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af449",
    children: "Cleaning Tools",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A variety of accurate and specific tools and products created and designed in various colors, materials, mechanisms, shapes, sizes and styles to clean easily, effectively and efficiently. Cleaning tools are vital to clean especially when your form of employment involves cleaning.",
    image: images["product-177.png"],
    originalPrice: 8,
    parent: "Household Tools",
    quantity: 100,
    slug: "scrub-brush",
    title: "Scrub Brush",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["cleaning tools"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af448",
    children: "Cleaning Tools",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A variety of accurate and specific tools and products created and designed in various colors, materials, mechanisms, shapes, sizes and styles to clean easily, effectively and efficiently. Cleaning tools are vital to clean especially when your form of employment involves cleaning.",
    image: images["product-178.png"],
    originalPrice: 9,
    parent: "Household Tools",
    quantity: 6,
    slug: "scrub-bob",
    title: "Scrub Bob",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-30T15:20:31.862Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["cleaning tools"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af447",
    children: "Cleaning Tools",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A variety of accurate and specific tools and products created and designed in various colors, materials, mechanisms, shapes, sizes and styles to clean easily, effectively and efficiently. Cleaning tools are vital to clean especially when your form of employment involves cleaning.",
    image: images["product-179.png"],
    originalPrice: 7,
    parent: "Household Tools",
    quantity: 100,
    slug: "casabella-sponge-brush",
    title: "Casabella Sponge Brush",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["tide-detergent", "laundry"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af446",
    children: "Laundry",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A laundry service is a service in a hotel that washes and irons clothes for guests. Almost all hotels have a laundry service. If you hand in clothes one day you should get them back a day or two later. The laundry service will wash, dry, and iron your clothes.",
    image: images["product-180.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 130,
    slug: "tide-detergent",
    title: "Tide Detergent",
    type: "Grocery",
    unit: "100oz",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["open-nature", "laundry"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af445",
    children: "Laundry",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A laundry service is a service in a hotel that washes and irons clothes for guests. Almost all hotels have a laundry service. If you hand in clothes one day you should get them back a day or two later. The laundry service will wash, dry, and iron your clothes.",
    image: images["product-181.png"],
    originalPrice: 11,
    parent: "Household Tools",
    quantity: 100,
    slug: "open-nature-chlorine",
    title: "Open Nature Chlorine",
    type: "Grocery",
    unit: "100oz",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["febreze-gain", "laundry"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af444",
    children: "Laundry",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A laundry service is a service in a hotel that washes and irons clothes for guests. Almost all hotels have a laundry service. If you hand in clothes one day you should get them back a day or two later. The laundry service will wash, dry, and iron your clothes.",
    image: images["product-182.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "febreze-gain-scent",
    title: "Febreze Gain Scent",
    type: "Grocery",
    unit: "32oz",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["febreze-lavender", "laundry"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af443",
    children: "Laundry",
    createdAt: "2021-12-22T16:41:23.201Z",
    description:
      "A laundry service is a service in a hotel that washes and irons clothes for guests. Almost all hotels have a laundry service. If you hand in clothes one day you should get them back a day or two later. The laundry service will wash, dry, and iron your clothes.",
    image: images["product-183.png"],
    originalPrice: 13,
    parent: "Household Tools",
    quantity: 100,
    slug: "febreze-moonlit-lavender",
    title: "Febreze Moonlit Lavender",
    type: "Grocery",
    unit: "9oz",
    updatedAt: "2021-12-22T16:41:23.201Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["arm-hammer", "laundry"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af442",
    children: "Laundry",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "A laundry service is a service in a hotel that washes and irons clothes for guests. Almost all hotels have a laundry service. If you hand in clothes one day you should get them back a day or two later. The laundry service will wash, dry, and iron your clothes.",
    image: images["product-184.png"],

    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "arm-hammer-detergent",
    title: "Arm Hammer Detergent",
    type: "Grocery",
    unit: "9oz",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["hot-shot", "pest-control"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af441",
    children: "Pest Control",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. ... This can be achieved by monitoring the crop, only applying insecticides when necessary, and by growing varieties and crops which are resistant to pests.",
    image: images["product-185.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "hot-shot-insect-killer",
    title: "Hot Shot Insect Killer",
    type: "Grocery",
    unit: "14oz",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["hot-shot", "pest-control"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af440",
    children: "Pest Control",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. ... This can be achieved by monitoring the crop, only applying insecticides when necessary, and by growing varieties and crops which are resistant to pests.",
    image: images["product-186.png"],
    originalPrice: 10,
    parent: "Household Tools",
    quantity: 120,
    slug: "hot-shot-ant-killer",
    title: "Hot Shot Ant Killer",
    type: "Grocery",
    unit: "17oz",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["d-con", "pest-control"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43f",
    children: "Pest Control",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. ... This can be achieved by monitoring the crop, only applying insecticides when necessary, and by growing varieties and crops which are resistant to pests.",
    image: images["product-187.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "d-con-trap-rat",
    title: "d Con Trap Rat",
    type: "Grocery",
    unit: "2ct",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["black-flag", "pest-control"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43e",
    children: "Pest Control",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. ... This can be achieved by monitoring the crop, only applying insecticides when necessary, and by growing varieties and crops which are resistant to pests.",
    image: images["product-188.png"],
    originalPrice: 14,
    parent: "Household Tools",
    quantity: 6,
    slug: "black-flag-ant-killer",
    title: "Black Flag Ant Killer",
    type: "Grocery",
    unit: "20oz",
    updatedAt: "2021-12-30T15:20:17.238Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["bayer", "pest-control"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43d",
    children: "Pest Control",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Pest control is the regulation or management of a species defined as a pest, a member of the animal kingdom that impacts adversely on human activities. ... This can be achieved by monitoring the crop, only applying insecticides when necessary, and by growing varieties and crops which are resistant to pests.",
    image: images["product-189.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "bayer-insect-killer",
    title: "Bayer Insect Killer",
    type: "Grocery",
    unit: "75oz",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["brita", "water-filter"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43c",
    children: "Water Filter",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Water filtering is a method used to filter out undesired chemical compounds, organic and inorganic materials, and biological contaminants from water. The purpose of water filtration is to provide clean drinking water.",
    image: images["product-190.png"],
    originalPrice: 10,
    parent: "Household Tools",
    quantity: 100,
    slug: "brita-water-filter",
    title: "BRITA Water Filter",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["brita", "water-filter"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43b",
    children: "Water Filter",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Water filtering is a method used to filter out undesired chemical compounds, organic and inorganic materials, and biological contaminants from water. The purpose of water filtration is to provide clean drinking water.",
    image: images["product-191.png"],
    originalPrice: 11,
    parent: "Household Tools",
    quantity: 7,
    slug: "brita-water-filter-dispenser",
    title: "BRITA Water Filter Dispenser",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-30T15:20:04.246Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["brita", "water-filter"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af43a",
    children: "Water Filter",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Water filtering is a method used to filter out undesired chemical compounds, organic and inorganic materials, and biological contaminants from water. The purpose of water filtration is to provide clean drinking water.",
    image: images["product-192.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "brita-water-filter-pitcher",
    title: "BRITA Water Filter Pitcher",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["brita", "water-filter"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af439",
    children: "Water Filter",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Water filtering is a method used to filter out undesired chemical compounds, organic and inorganic materials, and biological contaminants from water. The purpose of water filtration is to provide clean drinking water.",
    image: images["product-193.png"],
    originalPrice: 14,
    parent: "Household Tools",
    quantity: 60,
    slug: "brita-replacement-water-filter",
    title: "BRITA Replacement Water Filter",
    type: "Grocery",
    unit: "3each",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["brita", "water-filter"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af438",
    children: "Water Filter",
    createdAt: "2021-12-22T16:41:23.200Z",
    description:
      "Water filtering is a method used to filter out undesired chemical compounds, organic and inorganic materials, and biological contaminants from water. The purpose of water filtration is to provide clean drinking water.",
    image: images["product-194.png"],
    originalPrice: 12,
    parent: "Household Tools",
    quantity: 100,
    slug: "brita-water-filter",
    title: "BRITA Water Filter",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2021-12-22T16:41:23.200Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["bird-toy", "bird-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af437",
    children: "Bird Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Many species of birds make fun, engaging pets. If you're considering bird ownership, the care requirements include providing good housing, nutritious food, and keeping an eye on the bird's health. You'll also need to provide plenty of enrichment and interaction, to keep your pet bird happy and alert.",
    image: images["product-195.png"],
    originalPrice: 12,
    parent: "Pet Care",
    quantity: 150,
    slug: "bird-toy",
    title: "Bird Toy",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["bird-toy", "bird-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af436",
    children: "Bird Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Many species of birds make fun, engaging pets. If you're considering bird ownership, the care requirements include providing good housing, nutritious food, and keeping an eye on the bird's health. You'll also need to provide plenty of enrichment and interaction, to keep your pet bird happy and alert.",
    image: images["product-196.png"],
    originalPrice: 13,
    parent: "Pet Care",
    quantity: 100,
    slug: "foraging-pouch-bird-toy",
    title: "Foraging Pouch Bird Toy",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["bird-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af435",
    children: "Bird Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Many species of birds make fun, engaging pets. If you're considering bird ownership, the care requirements include providing good housing, nutritious food, and keeping an eye on the bird's health. You'll also need to provide plenty of enrichment and interaction, to keep your pet bird happy and alert.",
    image: images["product-197.png"],
    originalPrice: 12,
    parent: "Pet Care",
    quantity: 7,
    slug: "ecotrition-goofy-links",
    title: "Ecotrition Goofy Links",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-30T15:19:38.044Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["bird toy", "bird-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af434",
    children: "Bird Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Many species of birds make fun, engaging pets. If you're considering bird ownership, the care requirements include providing good housing, nutritious food, and keeping an eye on the bird's health. You'll also need to provide plenty of enrichment and interaction, to keep your pet bird happy and alert.",
    image: images["product-198.png"],
    originalPrice: 8,
    parent: "Pet Care",
    quantity: 80,
    slug: "paradise-toys",
    title: "Paradise Toys",
    type: "Grocery",
    unit: "6lb",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["bird toy", "bird-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af433",
    children: "Bird Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Many species of birds make fun, engaging pets. If you're considering bird ownership, the care requirements include providing good housing, nutritious food, and keeping an eye on the bird's health. You'll also need to provide plenty of enrichment and interaction, to keep your pet bird happy and alert.",
    image: images["product-199.png"],
    originalPrice: 7,
    parent: "Pet Care",
    quantity: 100,
    slug: "kaytee-bird-food",
    title: "Kaytee Bird Food",
    type: "Grocery",
    unit: "8lb",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cat-toy", "cat-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af432",
    children: "Cat Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Provide plenty of human companionship. Provide regular, suitable meals with a constant supply of fresh water. Provide a clean and comfortable bed. Provide the cat with outdoor access or be prepared to empty and clean a litter tray on a daily basis.",
    image: images["product-200.png"],
    originalPrice: 11,
    parent: "Pet Care",
    quantity: 130,
    slug: "cat-toy",
    title: "Cat Toy",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["cat-toy", "cat-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af431",
    children: "Cat Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Provide plenty of human companionship. Provide regular, suitable meals with a constant supply of fresh water. Provide a clean and comfortable bed. Provide the cat with outdoor access or be prepared to empty and clean a litter tray on a daily basis.",
    image: images["product-201.png"],
    originalPrice: 13,
    parent: "Pet Care",
    quantity: 100,
    slug: "multipack-ball-cat-toy",
    title: "Multipack Ball Cat Toy",
    type: "Grocery",
    unit: "5lb",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["cat-toy", "cat-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af430",
    children: "Cat Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Provide plenty of human companionship. Provide regular, suitable meals with a constant supply of fresh water. Provide a clean and comfortable bed. Provide the cat with outdoor access or be prepared to empty and clean a litter tray on a daily basis.",
    image: images["product-202.png"],
    originalPrice: 14,
    parent: "Pet Care",
    quantity: 80,
    slug: "leaps-bounds-cat-toy",
    title: "Leaps Bounds Cat Toy",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["cat-toy", "cat-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42f",
    children: "Cat Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Provide plenty of human companionship. Provide regular, suitable meals with a constant supply of fresh water. Provide a clean and comfortable bed. Provide the cat with outdoor access or be prepared to empty and clean a litter tray on a daily basis.",
    image: images["product-203.png"],
    originalPrice: 12,
    parent: "Pet Care",
    quantity: 100,
    slug: "premium-cat-food",
    title: "Premium Cat Food",
    type: "Grocery",
    unit: "16lb",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["cat-toy", "cat-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42e",
    children: "Cat Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Provide plenty of human companionship. Provide regular, suitable meals with a constant supply of fresh water. Provide a clean and comfortable bed. Provide the cat with outdoor access or be prepared to empty and clean a litter tray on a daily basis.",
    image: images["product-204.png"],
    originalPrice: 13,
    parent: "Pet Care",
    quantity: 100,
    slug: "vine-blend-cat-toy",
    title: "Vine Blend Cat Toy",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["dog-diet", "dog-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42d",
    children: "Dog Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Dog Sitters supervise pets and take care of them in their owners' absence. Typical activities seen on a Dog Sitter example resume are grooming, providing food and water, taking dogs out for walk, taking dogs to vet appointments, administering medication, providing companionship, and following owner instructions.",
    image: images["product-205.png"],
    originalPrice: 11,
    parent: "Pet Care",
    quantity: 100,
    slug: "hill-science-diet-dog",
    title: "Hill Science Diet Dog",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["dog-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42c",
    children: "Dog Care",
    createdAt: "2021-12-22T16:41:23.199Z",
    description:
      "Dog Sitters supervise pets and take care of them in their owners' absence. Typical activities seen on a Dog Sitter example resume are grooming, providing food and water, taking dogs out for walk, taking dogs to vet appointments, administering medication, providing companionship, and following owner instructions.",
    image: images["product-206.png"],
    originalPrice: 14,
    parent: "Pet Care",
    quantity: 100,
    slug: "halloween-dog-taco",
    title: "Halloween Dog Taco",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.199Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["dog-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42b",
    children: "Dog Care",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "Dog Sitters supervise pets and take care of them in their owners' absence. Typical activities seen on a Dog Sitter example resume are grooming, providing food and water, taking dogs out for walk, taking dogs to vet appointments, administering medication, providing companionship, and following owner instructions.",
    image: images["product-207.png"],
    originalPrice: 13,
    parent: "Pet Care",
    quantity: 10,
    slug: "colorado-dog-collar",
    title: "Colorado Dog Collar",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-30T15:19:23.097Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["dog-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af42a",
    children: "Dog Care",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "Dog Sitters supervise pets and take care of them in their owners' absence. Typical activities seen on a Dog Sitter example resume are grooming, providing food and water, taking dogs out for walk, taking dogs to vet appointments, administering medication, providing companionship, and following owner instructions.",
    image: images["product-208.png"],
    originalPrice: 12,
    parent: "Pet Care",
    quantity: 100,
    slug: "bling-dog-collar",
    title: "Bling Dog Collar",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["dog-care"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af429",
    children: "Dog Care",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "Dog Sitters supervise pets and take care of them in their owners' absence. Typical activities seen on a Dog Sitter example resume are grooming, providing food and water, taking dogs out for walk, taking dogs to vet appointments, administering medication, providing companionship, and following owner instructions.",
    image: images["product-209.png"],
    originalPrice: 14,
    parent: "Pet Care",
    quantity: 100,
    slug: "popsicle-dog-collar",
    title: "Popsicle Dog Collar",
    type: "Grocery",
    unit: "each",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["hibiscus-corn", "canned-food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af428",
    children: "Canned Food",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "canned food - food preserved by canning. canned foods, canned goods, tinned goods. food product, foodstuff - a substance that can be used or prepared for use as food. canned meat, tinned meat - meat preserved in a can or tin.",
    image: images["product-210.png"],
    originalPrice: 11,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "hibiscus-baby-corn",
    title: "Hibiscus Baby Corn",
    type: "Grocery",
    unit: "425gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["figaro-olives", "canned-food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af427",
    children: "Canned Food",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "canned food - food preserved by canning. canned foods, canned goods, tinned goods. food product, foodstuff - a substance that can be used or prepared for use as food. canned meat, tinned meat - meat preserved in a can or tin.",
   image: images["product-211.png"],
    originalPrice: 13,
    parent: "Snacks & Instant",
    quantity: 37,
    slug: "figaro-green-olives",
    title: "Figaro Green Olives",
    type: "Grocery",
    unit: "340gm",
    updatedAt: "2022-06-20T06:39:32.555Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["borges-olives", "canned-food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af426",
    children: "Canned Food",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "canned food - food preserved by canning. canned foods, canned goods, tinned goods. food product, foodstuff - a substance that can be used or prepared for use as food. canned meat, tinned meat - meat preserved in a can or tin.",
      image: images["product-212.png"],
    originalPrice: 12,
    parent: "Snacks & Instant",
    quantity: 5,
    slug: "borges-green-olive",
    title: "Borges Green Olive",
    type: "Grocery",
    unit: "320gm",
    updatedAt: "2021-12-30T15:19:10.481Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["borges-olives", "canned-food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af425",
    children: "Canned Food",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "canned food - food preserved by canning. canned foods, canned goods, tinned goods. food product, foodstuff - a substance that can be used or prepared for use as food. canned meat, tinned meat - meat preserved in a can or tin.",
      image: images["product-213.png"],
    originalPrice: 13,
    parent: "Snacks & Instant",
    quantity: 140,
    slug: "borges-green-olives",
    title: "Borges Green Olives",
    type: "Grocery",
    unit: "330gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["american-mushroom", "canned-food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af424",
    children: "Canned Food",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "canned food - food preserved by canning. canned foods, canned goods, tinned goods. food product, foodstuff - a substance that can be used or prepared for use as food. canned meat, tinned meat - meat preserved in a can or tin.",
      image: images["product-214.png"],
    originalPrice: 12,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "american-mushroom",
    title: "American Mushroom",
    type: "Grocery",
    unit: "425gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["lays-salted", "chips & nuts"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af423",
    children: "Chips & Nuts",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-215.png"],
    originalPrice: 11,
    parent: "Snacks & Instant",
    quantity: 50,
    slug: "lays-classic-salted",
    title: "Lays Classic Salted",
    type: "Grocery",
    unit: "25gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["lays-chips", "chips & nuts"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af422",
    children: "Chips & Nuts",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-216.png"],
    originalPrice: 9,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "lays-american-chips",
    title: "Lays American Chips",
    type: "Grocery",
    unit: "25gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["kurkure", "chips & nuts"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af421",
    children: "Chips & Nuts",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-217.png"],
    originalPrice: 10,
    parent: "Snacks & Instant",
    quantity: 60,
    slug: "kurkure-crackers",
    title: "Kurkure Crackers",
    type: "Grocery",
    unit: "25gm",
    updatedAt: "2021-12-22T16:41:23.198Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["chips & nuts"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af420",
    children: "Chips & Nuts",
    createdAt: "2021-12-22T16:41:23.198Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-218.png"],
    originalPrice: 12,
    parent: "Snacks & Instant",
    quantity: 10,
    slug: "camel-smoked-almonds",
    title: "Camel Smoked Almonds",
    type: "Grocery",
    unit: "40gm",
    updatedAt: "2021-12-30T15:18:56.185Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["chips & nuts"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41f",
    children: "Chips & Nuts",
    createdAt: "2021-12-22T16:41:23.197Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-219.png"],
    originalPrice: 10,
    parent: "Snacks & Instant",
    quantity: 20,
    slug: "blue-diamond-almonds",
    title: "Blue Diamond Almonds",
    type: "Grocery",
    unit: "40gm",
    updatedAt: "2021-12-22T16:41:23.197Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["choco", "Chocolate"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41e",
    children: "Chocolate",
    createdAt: "2021-12-22T16:41:23.197Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-220.png"],
    originalPrice: 9,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "choco-chocolate",
    title: "Choco Chocolate",
    type: "Grocery",
    unit: "45gm",
    updatedAt: "2021-12-22T16:41:23.197Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["lotte", "Chocolate"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41d",
    children: "Chocolate",
    createdAt: "2021-12-22T16:41:23.197Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-221.png"],
    originalPrice: 13,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "lotte-almond-chocoball",
    title: "Lotte Almond Chocoball",
    type: "Grocery",
    unit: "46gm",
    updatedAt: "2021-12-22T16:41:23.197Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["Chocolate"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41c",
    children: "Chocolate",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-222.png"],
    originalPrice: 10,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "passiar-mints",
    title: "Passiar Mints",
    type: "Grocery",
    unit: "180g",
    updatedAt: "2021-12-22T16:41:23.196Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["Chocolate"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41b",
    children: "Chocolate",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-223.png"],
    originalPrice: 11,
    parent: "Snacks & Instant",
    quantity: 99,
    slug: "assorted-chocolate-box",
    title: "Assorted Chocolate Box",
    type: "Grocery",
    unit: "200gm",
    updatedAt: "2022-06-10T00:28:44.568Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["candy"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af41a",
    children: "Chocolate",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "a small usually thin and flat piece (as of wood or stone) cut, struck, or flaked off. b : a small piece of food: such as. (1) : a small, thin, crisp, usually salty piece of food typically prepared by frying, baking, or drying banana chips especially : potato chip — see also corn chip.",
      image: images["product-224.png"],
    originalPrice: 10,
    parent: "Snacks & Instant",
    quantity: 99,
    slug: "caramel-candy",
    title: "Caramel Candy",
    type: "Grocery",
    unit: "225g",
    updatedAt: "2022-06-10T00:28:44.569Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["mr-noodles", "noodles"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af419",
    children: "Noodles",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "Noodles are a type of food made from unleavened dough which is rolled flat and cut, stretched or extruded, into long strips or strings. ... Noodles are usually cooked in boiling water, sometimes with cooking oil or salt added. They are also often pan-fried or deep-fried.",
      image: images["product-225.png"],
    originalPrice: 10,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "mr-noodles-magic-masala",
    title: "Mr Noodles Magic Masala",
    type: "Grocery",
    unit: "40g",
    updatedAt: "2021-12-22T16:41:23.196Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["mama-noodles", "noodles"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af418",
    children: "Noodles",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "Noodles are a type of food made from unleavened dough which is rolled flat and cut, stretched or extruded, into long strips or strings. ... Noodles are usually cooked in boiling water, sometimes with cooking oil or salt added. They are also often pan-fried or deep-fried.",
      image: images["product-226.png"],
    originalPrice: 15,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "mama-hot-spicy-noodles",
    title: "Mama Hot Spicy Noodles",
    type: "Grocery",
    unit: "496gm",
    updatedAt: "2021-12-22T16:41:23.196Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["mama-noodles", "noodles"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af417",
    children: "Noodles",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "Noodles are a type of food made from unleavened dough which is rolled flat and cut, stretched or extruded, into long strips or strings. ... Noodles are usually cooked in boiling water, sometimes with cooking oil or salt added. They are also often pan-fried or deep-fried.",
      image: images["product-227.png"],
    originalPrice: 15,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "mama-hot-spicy-flovour",
    title: "Mama Hot Spicy Flovour",
    type: "Grocery",
    unit: "248g",
    updatedAt: "2021-12-22T16:41:23.196Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["maggi-noodles", "noodles"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af416",
    children: "Noodles",
    createdAt: "2021-12-22T16:41:23.196Z",
    description:
      "Noodles are a type of food made from unleavened dough which is rolled flat and cut, stretched or extruded, into long strips or strings. ... Noodles are usually cooked in boiling water, sometimes with cooking oil or salt added. They are also often pan-fried or deep-fried.",
      image: images["product-228.png"],
    originalPrice: 15,
    parent: "Snacks & Instant",
    quantity: 5,
    slug: "maggi-noodles",
    title: "Maggi Noodles",
    type: "Grocery",
    unit: "260g",
    updatedAt: "2021-12-30T15:18:40.462Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["doodles-noodles", "noodles"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af415",
    children: "Noodles",
    createdAt: "2021-12-22T16:41:23.195Z",
    description:
      "Noodles are a type of food made from unleavened dough which is rolled flat and cut, stretched or extruded, into long strips or strings. ... Noodles are usually cooked in boiling water, sometimes with cooking oil or salt added. They are also often pan-fried or deep-fried.",
      image: images["product-229.png"],
    originalPrice: 15,
    parent: "Snacks & Instant",
    quantity: 100,
    slug: "doodles-noodles",
    title: "Doodles Noodles",
    type: "Grocery",
    unit: "180g",
    updatedAt: "2021-12-22T16:41:23.195Z",
    sku: "",
    __v: 0,
  },
  {
    price: 20,
    discount: 0,
    tag: ["dabur", "honey"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af414",
    children: "Honey",
    createdAt: "2021-12-22T16:41:23.195Z",
    description:
      "honey, sweet, viscous liquid food, dark golden in colour, produced in the honey sacs of various bees from the nectar of flowers. Flavour and colour are determined by the flowers from which the nectar is gathered. Some of the most commercially desirable honeys are produced from clover by the domestic honeybee.",
      image: images["product-230.png"],
    originalPrice: 20,
    parent: "Honey",
    quantity: 99,
    slug: "dabur-honey-oil-free",
    title: "Dabur Honey Oil Free",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-06-20T07:22:05.512Z",
    sku: "",
    __v: 0,
  },
  {
    price: 20,
    discount: 0,
    tag: ["aussiebee-honey", "honey"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af413",
    children: "Honey",
    createdAt: "2021-12-22T16:41:23.195Z",
    description:
      "honey, sweet, viscous liquid food, dark golden in colour, produced in the honey sacs of various bees from the nectar of flowers. Flavour and colour are determined by the flowers from which the nectar is gathered. Some of the most commercially desirable honeys are produced from clover by the domestic honeybee.",
      image: images["product-231.png"],
    originalPrice: 20,
    parent: "Honey",
    quantity: 4,
    slug: "aussiebee-honey-glass-bottle",
    title: "Aussiebee Honey Glass Bottle",
    type: "Grocery",
    unit: "312g",
    updatedAt: "2022-06-20T07:22:05.512Z",
    sku: "",
    __v: 0,
  },
  {
    price: 16,
    discount: 0,
    tag: ["aussiebee-honey", "honey"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af412",
    children: "Honey",
    createdAt: "2021-12-22T16:41:23.195Z",
    description:
      "honey, sweet, viscous liquid food, dark golden in colour, produced in the honey sacs of various bees from the nectar of flowers. Flavour and colour are determined by the flowers from which the nectar is gathered. Some of the most commercially desirable honeys are produced from clover by the domestic honeybee.",
      image: images["product-232.png"],
    originalPrice: 16,
    parent: "Honey",
    quantity: 99,
    slug: "aussiebee-honey",
    title: "Aussiebee Honey",
    type: "Grocery",
    unit: "375g",
    updatedAt: "2022-05-14T04:24:31.873Z",
    sku: "",
    __v: 0,
  },
  {
    price: 19,
    discount: 0,
    tag: ["aussiebee-honey", "honey"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af411",
    children: "Honey",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "honey, sweet, viscous liquid food, dark golden in colour, produced in the honey sacs of various bees from the nectar of flowers. Flavour and colour are determined by the flowers from which the nectar is gathered. Some of the most commercially desirable honeys are produced from clover by the domestic honeybee.",
      image: images["product-233.png"],
    originalPrice: 19,
    parent: "Honey",
    quantity: 99,
    slug: "aussiebee-honey-bottle",
    title: "Aussiebee Honey Bottle",
    type: "Grocery",
    unit: "400g",
    updatedAt: "2022-05-14T04:24:31.873Z",
    sku: "",
    __v: 0,
  },
  {
    price: 17,
    discount: 0,
    tag: ["alshifa-honey", "honey"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af410",
    children: "Honey",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "honey, sweet, viscous liquid food, dark golden in colour, produced in the honey sacs of various bees from the nectar of flowers. Flavour and colour are determined by the flowers from which the nectar is gathered. Some of the most commercially desirable honeys are produced from clover by the domestic honeybee.",
      image: images["product-234.png"],
    originalPrice: 17,
    parent: "Honey",
    quantity: 141,
    slug: "alshifa-natural-honey",
    title: "Alshifa Natural Honey",
    type: "Grocery",
    unit: "250g",
    updatedAt: "2022-05-14T04:24:31.873Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["pran-jam", "jam & jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40f",
    children: "Jam & Jelly",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Jams and jellies are spreads typically made from fruit, sugar, and pectin. Jelly is made with the juice of the fruit; jam uses the meat of the fruit as well. Some vegetable jellies are also produced.",
      image: images["product-235.png"],
    originalPrice: 11,
    parent: "Jam & Jelly",
    quantity: 100,
    slug: "pran-mixed-fruit-jam",
    title: "Pran Mixed Fruit Jam",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2021-12-22T16:41:23.194Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["pran-jam", "jam & jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40e",
    children: "Jam & Jelly",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Jams and jellies are spreads typically made from fruit, sugar, and pectin. Jelly is made with the juice of the fruit; jam uses the meat of the fruit as well. Some vegetable jellies are also produced.",
      image: images["product-236.png"],
    originalPrice: 13,
    parent: "Jam & Jelly",
    quantity: 3,
    slug: "pran-mango-jam",
    title: "Pran Mango Jam",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2022-05-09T23:32:40.512Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["pran-jelly", "jam & jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40d",
    children: "Jam & Jelly",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Jams and jellies are spreads typically made from fruit, sugar, and pectin. Jelly is made with the juice of the fruit; jam uses the meat of the fruit as well. Some vegetable jellies are also produced.",
      image: images["product-237.png"],
    originalPrice: 14,
    parent: "Jam & Jelly",
    quantity: 94,
    slug: "pran-apple-jelly",
    title: "Pran Apple Jelly",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2022-05-09T23:32:40.511Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["foster-clark-jam", "jam & jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40c",
    children: "Jam & Jelly",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Jams and jellies are spreads typically made from fruit, sugar, and pectin. Jelly is made with the juice of the fruit; jam uses the meat of the fruit as well. Some vegetable jellies are also produced.",
      image: images["product-238.png"],
    originalPrice: 12,
    parent: "Jam & Jelly",
    quantity: 99,
    slug: "foster-clark-jam",
    title: "Foster Clark Jam",
    type: "Grocery",
    unit: "450gm",
    updatedAt: "2022-06-09T11:41:56.874Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["fruit-jelly", "jam & jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40b",
    children: "Jam & Jelly",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Jams and jellies are spreads typically made from fruit, sugar, and pectin. Jelly is made with the juice of the fruit; jam uses the meat of the fruit as well. Some vegetable jellies are also produced.",
      image: images["product-239.png"],
    originalPrice: 13,
    parent: "Jam & Jelly",
    quantity: 100,
    slug: "ahmed-fruit-jelly",
    title: "Ahmed Fruit Jelly",
    type: "Grocery",
    unit: "500g",
    updatedAt: "2021-12-22T16:41:23.194Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["skin care", "lotion"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af40a",
    children: "Skin Care",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. Skin care is a part of the treatment of wound healing, radiation therapy and some medications.",
      image: images["product-240.png"],

    originalPrice: 11,
    parent: "Beauty & Health",
    quantity: 99,
    slug: "goddess-garden-sunscreen",
    title: "Goddess Garden Sunscreen",
    type: "Health Care",
    unit: "4oz",
    updatedAt: "2022-05-06T00:57:31.252Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["skin care", "lotion"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af409",
    children: "Skin Care",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. Skin care is a part of the treatment of wound healing, radiation therapy and some medications.",
      image: images["product-241.png"],
    originalPrice: 7,
    parent: "Beauty & Health",
    quantity: 99,
    slug: "sunscreen-lotion",
    title: "Sunscreen Lotion",
    type: "Health Care",
    unit: "each",
    updatedAt: "2022-05-06T00:57:31.253Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["skin care", "face wash"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af408",
    children: "Skin Care",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. Skin care is a part of the treatment of wound healing, radiation therapy and some medications.",
      image: images["product-242.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 5,
    slug: "erin-face-wash",
    title: "Erin Face Wash",
    type: "Health Care",
    unit: "each",
    updatedAt: "2021-12-30T15:17:43.911Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["skin care", "lotion"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af407",
    children: "Skin Care",
    createdAt: "2021-12-22T16:41:23.194Z",
    description:
      "Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. Skin care is a part of the treatment of wound healing, radiation therapy and some medications.",
      image: images["product-243.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "best-choice-soap",
    title: "Best Choice Soap",
    type: "Health Care",
    unit: "5fl oz",
    updatedAt: "2021-12-22T16:41:23.194Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["skin care", "lotion"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af406",
    children: "Skin Care",
    createdAt: "2021-12-22T16:41:23.192Z",
    description:
      "Skin care is the range of practices that support skin integrity, enhance its appearance and relieve skin conditions. Skin care is a part of the treatment of wound healing, radiation therapy and some medications.",
      image: images["product-244.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "banana-boat-lotion",
    title: "Banana Boat Lotion",
    type: "Health Care",
    unit: "2fl oz",
    updatedAt: "2021-12-22T16:41:23.192Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["gillette", "shaving needs"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af405",
    children: "Shaving Needs",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Health products are defined as those substances which gives energy or makes the person healthy. Health products are vitamins, minerals, herbal medicines, homeopathic preparations, probiotics and even some traditional medicines are also prescribed by doctor or any equivalent physician.",
      image: images["product-245.png"],
    originalPrice: 7,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "gillette-vector",
    title: "Gillette Vector",
    type: "Health Care",
    unit: "2s",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["gillette", "shaving needs"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af404",
    children: "Shaving Needs",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Health products are defined as those substances which gives energy or makes the person healthy. Health products are vitamins, minerals, herbal medicines, homeopathic preparations, probiotics and even some traditional medicines are also prescribed by doctor or any equivalent physician.",
      image: images["product-246.png"],
    originalPrice: 6,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "gillette-vector-refillable",
    title: "Gillette Vector Refillable",
    type: "Health Care",
    unit: "100ml",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["schick", "shaving needs"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af403",
    children: "Shaving Needs",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Health products are defined as those substances which gives energy or makes the person healthy. Health products are vitamins, minerals, herbal medicines, homeopathic preparations, probiotics and even some traditional medicines are also prescribed by doctor or any equivalent physician.",
      image: images["product-247.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "schick-exacta",
    title: "Schick Exacta",
    type: "Health Care",
    unit: "2p",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["gillette", "shaving needs"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af402",
    children: "Shaving Needs",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Health products are defined as those substances which gives energy or makes the person healthy. Health products are vitamins, minerals, herbal medicines, homeopathic preparations, probiotics and even some traditional medicines are also prescribed by doctor or any equivalent physician.",
      image: images["product-248.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "gillette-foam",
    title: "Gillette Foam",
    type: "Health Care",
    unit: "90g",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["denim", "shaving needs"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af401",
    children: "Shaving Needs",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Health products are defined as those substances which gives energy or makes the person healthy. Health products are vitamins, minerals, herbal medicines, homeopathic preparations, probiotics and even some traditional medicines are also prescribed by doctor or any equivalent physician.",
      image: images["product-249.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "denim-after-shave",
    title: "Denim After Shave",
    type: "Health Care",
    unit: "100ml",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["oral care", "toothbrush"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af400",
    children: "Oral Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Oral Care Products are intended to cleanse the oral cavity, freshen the breath, and maintain good oral hygiene. Some products also include ingredients to protect against the formation of cavities.",
      image: images["product-250.png"],
    originalPrice: 5,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "pepsodent-toothbrush",
    title: "Pepsodent Toothbrush",
    type: "Health Care",
    unit: "1pcs",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["oral care", "toothpaste"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ff",
    children: "Oral Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Oral Care Products are intended to cleanse the oral cavity, freshen the breath, and maintain good oral hygiene. Some products also include ingredients to protect against the formation of cavities.",
      image: images["product-251.png"],
    originalPrice: 10,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "kshore-toothpaste",
    title: "Kshore Toothpaste",
    type: "Health Care",
    unit: "14g",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["oral care", "toothpaste"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3fe",
    children: "Oral Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Oral Care Products are intended to cleanse the oral cavity, freshen the breath, and maintain good oral hygiene. Some products also include ingredients to protect against the formation of cavities.",
      image: images["product-252.png"],
    originalPrice: 7,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "eurofresh-toothpaste",
    title: "Eurofresh Toothpaste",
    type: "Health Care",
    unit: "50g",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["oral care", "mouthwash"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3fd",
    children: "Oral Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Oral Care Products are intended to cleanse the oral cavity, freshen the breath, and maintain good oral hygiene. Some products also include ingredients to protect against the formation of cavities.",
      image: images["product-253.png"],
    originalPrice: 12,
    parent: "Beauty & Health",
    quantity: 5,
    slug: "colgate-mouthwash",
    title: "Colgate Mouthwash",
    type: "Health Care",
    unit: "250ml",
    updatedAt: "2021-12-30T15:17:27.162Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["oral care", "toothpaste"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3fc",
    children: "Oral Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Oral Care Products are intended to cleanse the oral cavity, freshen the breath, and maintain good oral hygiene. Some products also include ingredients to protect against the formation of cavities.",
      image: images["product-254.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "closeup-toothpaste",
    title: "Closeup Toothpaste",
    type: "Health Care",
    unit: "14g",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["hair care", "conditioner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3fb",
    children: "Hair Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Hair Care products are those that help to control the properties and behavior of the hair so that it can be maintained in a controlled and desirable manner. This can include hair conditioners, hair sprays, hair straighteners and relaxers, permanent waves, shampoos, rinses, tonics and dressings.",
      image: images["product-255.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "redken-conditioner",
    title: "Redken Conditioner",
    type: "Health Care",
    unit: "8fl oz",
    updatedAt: "2021-12-22T16:41:23.191Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["hair care", "conditioner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3fa",
    children: "Hair Care",
    createdAt: "2021-12-22T16:41:23.191Z",
    description:
      "Hair Care products are those that help to control the properties and behavior of the hair so that it can be maintained in a controlled and desirable manner. This can include hair conditioners, hair sprays, hair straighteners and relaxers, permanent waves, shampoos, rinses, tonics and dressings.",
      image: images["product-256.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 10,
    slug: "pantene-hair-care",
    title: "Pantene Hair Care",
    type: "Health Care",
    unit: "9fl oz",
    updatedAt: "2021-12-30T15:17:12.481Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["hair care", "shampoo"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f9",
    children: "Hair Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Hair Care products are those that help to control the properties and behavior of the hair so that it can be maintained in a controlled and desirable manner. This can include hair conditioners, hair sprays, hair straighteners and relaxers, permanent waves, shampoos, rinses, tonics and dressings.",
      image: images["product-257.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "herbal-shampoo",
    title: "Herbal Shampoo",
    type: "Health Care",
    unit: "8fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["hair care", "conditioner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f8",
    children: "Hair Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Hair Care products are those that help to control the properties and behavior of the hair so that it can be maintained in a controlled and desirable manner. This can include hair conditioners, hair sprays, hair straighteners and relaxers, permanent waves, shampoos, rinses, tonics and dressings.",
      image: images["product-258.png"],
    originalPrice: 7,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "dark-lovely-conditioner",
    title: "Dark & Lovely Conditioner",
    type: "Health Care",
    unit: "12fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["hair care", "conditioner"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f7",
    children: "Hair Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Hair Care products are those that help to control the properties and behavior of the hair so that it can be maintained in a controlled and desirable manner. This can include hair conditioners, hair sprays, hair straighteners and relaxers, permanent waves, shampoos, rinses, tonics and dressings.",
      image: images["product-259.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "clear-conditioner",
    title: "Clear Conditioner",
    type: "Health Care",
    unit: "12fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 6,
    discount: 0,
    tag: ["cosmetics", "lipstick"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f6",
    children: "Cosmetics",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "A cosmetic product shall mean any substance or mixture intended to be placed in contact with the various external parts of the human body (epidermis, hair system, nails, lips and external genital organs) or with the teeth and the mucous membranes of the oral cavity with a view exclusively or mainly to cleaning them",
      image: images["product-260.png"],
    originalPrice: 6,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "revlon-lipstick",
    title: "Revlon Lipstick",
    type: "Health Care",
    unit: "15fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cosmetics", "makeup"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f5",
    children: "Cosmetics",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "A cosmetic product shall mean any substance or mixture intended to be placed in contact with the various external parts of the human body (epidermis, hair system, nails, lips and external genital organs) or with the teeth and the mucous membranes of the oral cavity with a view exclusively or mainly to cleaning them",
      image: images["product-261.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 10,
    slug: "firming-makeup",
    title: "Firming Makeup",
    type: "Health Care",
    unit: "5fl oz",
    updatedAt: "2021-12-30T15:16:54.773Z",
    sku: "",
    __v: 0,
  },
  {
    price: 5,
    discount: 0,
    tag: ["cosmetics", "nail color"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f4",
    children: "Cosmetics",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "A cosmetic product shall mean any substance or mixture intended to be placed in contact with the various external parts of the human body (epidermis, hair system, nails, lips and external genital organs) or with the teeth and the mucous membranes of the oral cavity with a view exclusively or mainly to cleaning them",
      image: images["product-262.png"],
    originalPrice: 5,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "essie-bnil-color",
    title: "Essie Nail Color",
    type: "Health Care",
    unit: "46fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["cosmetics", "nail polis"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f3",
    children: "Cosmetics",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "A cosmetic product shall mean any substance or mixture intended to be placed in contact with the various external parts of the human body (epidermis, hair system, nails, lips and external genital organs) or with the teeth and the mucous membranes of the oral cavity with a view exclusively or mainly to cleaning them",
      image: images["product-263.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "nail-polish",
    title: "Nail Polish",
    type: "Health Care",
    unit: "5fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["cosmetics", "lipcolor"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f2",
    children: "Cosmetics",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "A cosmetic product shall mean any substance or mixture intended to be placed in contact with the various external parts of the human body (epidermis, hair system, nails, lips and external genital organs) or with the teeth and the mucous membranes of the oral cavity with a view exclusively or mainly to cleaning them",
      image: images["product-264.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "orchid-lipcolor",
    title: "Orchid Lipcolor",
    type: "Health Care",
    unit: "17fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 7,
    discount: 0,
    tag: ["body care", "lotion"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f1",
    children: "Body Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Body care means how you perform with passive range of motion, applications of dressings and ointments or lotions to the body, and pedicure to trim toenails and apply lotion to feet.",
      image: images["product-265.png"],
    originalPrice: 7,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "body-lotion",
    title: "Body Lotion",
    type: "Health Care",
    unit: "5fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["body care", "creme"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3f0",
    children: "Body Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Body care means how you perform with passive range of motion, applications of dressings and ointments or lotions to the body, and pedicure to trim toenails and apply lotion to feet.",
      image: images["product-266.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "dove-creme",
    title: "Dove Creme",
    type: "Health Care",
    unit: "18fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["body care", "seed oil"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ef",
    children: "Body Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Body care means how you perform with passive range of motion, applications of dressings and ointments or lotions to the body, and pedicure to trim toenails and apply lotion to feet.",
      image: images["product-267.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "seed-oil",
    title: "Seed Oil",
    type: "Health Care",
    unit: "16fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["body care", "gel"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ee",
    children: "Body Care",
    createdAt: "2021-12-22T16:41:23.190Z",
    description:
      "Body care means how you perform with passive range of motion, applications of dressings and ointments or lotions to the body, and pedicure to trim toenails and apply lotion to feet.",
      image: images["product-268.png"],
    originalPrice: 9,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "cucumber-melon",
    title: "Cucumber Melon",
    type: "Health Care",
    unit: "12fl oz",
    updatedAt: "2021-12-22T16:41:23.190Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["body care", "gel"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ed",
    children: "Body Care",
    createdAt: "2021-12-22T16:41:23.189Z",
    description:
      "Body care means how you perform with passive range of motion, applications of dressings and ointments or lotions to the body, and pedicure to trim toenails and apply lotion to feet.",
      image: images["product-269.png"],
    originalPrice: 8,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "avalon-organics-gel",
    title: "Avalon Organics Gel",
    type: "Health Care",
    unit: "12oz",
    updatedAt: "2021-12-22T16:41:23.189Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["bath", "soap"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ec",
    children: "Bath",
    createdAt: "2021-12-22T16:41:23.189Z",
    description:
      "bathroom accessories are items specifically designed for use in a bathroom, such as soap dishes, towel racks, etc. bathroom accessories accessories typically have durable, decorative finishes.",
      image: images["product-270.png"],
    originalPrice: 12,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "savlon-soap",
    title: "Savlon Soap",
    type: "Health Care",
    unit: "100gm",
    updatedAt: "2021-12-22T16:41:23.189Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["bath", "soap"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3eb",
    children: "Bath",
    createdAt: "2021-12-22T16:41:23.189Z",
    description:
      "bathroom accessories are items specifically designed for use in a bathroom, such as soap dishes, towel racks, etc. bathroom accessories accessories typically have durable, decorative finishes.",
      image: images["product-271.png"],
    originalPrice: 13,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "sandalina-soap",
    title: "Sandalina Soap",
    type: "Health Care",
    unit: "125gm",
    updatedAt: "2021-12-22T16:41:23.189Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["bath", "soap"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3ea",
    children: "Bath",
    createdAt: "2021-12-22T16:41:23.189Z",
    description:
      "bathroom accessories are items specifically designed for use in a bathroom, such as soap dishes, towel racks, etc. bathroom accessories accessories typically have durable, decorative finishes.",
      image: images["product-272.png"],
    originalPrice: 11,
    parent: "Beauty & Health",
    quantity: 10,
    slug: "nivea-soap",
    title: "Nivea Soap",
    type: "Health Care",
    unit: "75g",
    updatedAt: "2021-12-30T15:16:37.410Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["bath", "soap"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e9",
    children: "Bath",
    createdAt: "2021-12-22T16:41:23.189Z",
    description:
      "bathroom accessories are items specifically designed for use in a bathroom, such as soap dishes, towel racks, etc. bathroom accessories accessories typically have durable, decorative finishes.",
      image: images["product-273.png"],
    originalPrice: 12,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "neem-original-soap",
    title: "Neem Original Soap",
    type: "Health Care",
    unit: "75g",
    updatedAt: "2021-12-22T16:41:23.189Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["bath", "soap"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e8",
    children: "Bath",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "bathroom accessories are items specifically designed for use in a bathroom, such as soap dishes, towel racks, etc. bathroom accessories accessories typically have durable, decorative finishes.",
      image: images["product-274.png"],
    originalPrice: 11,
    parent: "Beauty & Health",
    quantity: 100,
    slug: "harmony-oranges-soap",
    title: "Harmony Orange Soap",
    type: "Health Care",
    unit: "70g",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 16,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e7",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby foods are either a soft, liquid paste or an easily chewed food since babies lack developed muscles and teeth to effectively chew. Babies typically move to consuming baby food once nursing or formula is not sufficient for the child's appetite. Babies do not need to have teeth to transition to eating solid foods.",
      image: images["product-275.png"],
    originalPrice: 16,
    parent: "Baby Care",
    quantity: 100,
    slug: "glory-diaper",
    title: "Glory Diaper",
    type: "Health Care",
    unit: "8pcs",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["baby care", "baby food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e6",
    children: "Baby Food",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby foods are either a soft, liquid paste or an easily chewed food since babies lack developed muscles and teeth to effectively chew. Babies typically move to consuming baby food once nursing or formula is not sufficient for the child's appetite. Babies do not need to have teeth to transition to eating solid foods.",
      image: images["product-276.png"],

    originalPrice: 15,
    parent: "Baby Care",
    quantity: 62,
    slug: "cerelac-cornflakes",
    title: "Cerelac Cornflakes",
    type: "Health Care",
    unit: "400gm",
    updatedAt: "2022-05-18T16:20:23.937Z",
    sku: "",
    __v: 0,
  },
  {
    price: 17,
    discount: 0,
    tag: ["baby care", "baby food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e5",
    children: "Baby Food",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby foods are either a soft, liquid paste or an easily chewed food since babies lack developed muscles and teeth to effectively chew. Babies typically move to consuming baby food once nursing or formula is not sufficient for the child's appetite. Babies do not need to have teeth to transition to eating solid foods.",
      image: images["product-277.png"],
    originalPrice: 17,
    parent: "Baby Care",
    quantity: 100,
    slug: "cerelac-cherry",
    title: "Cerelac Cherry",
    type: "Health Care",
    unit: "400gm",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 16,
    discount: 0,
    tag: ["baby care", "baby food"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e4",
    children: "Baby Food",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby foods are either a soft, liquid paste or an easily chewed food since babies lack developed muscles and teeth to effectively chew. Babies typically move to consuming baby food once nursing or formula is not sufficient for the child's appetite. Babies do not need to have teeth to transition to eating solid foods.",
      image: images["product-278.png"],
    originalPrice: 16,
    parent: "Baby Care",
    quantity: 100,
    slug: "biomil-1",
    title: "Biomil-1",
    type: "Health Care",
    unit: "200gm",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e3",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.",
      image: images["product-279.png"],
    originalPrice: 10,
    parent: "Baby Care",
    quantity: 6,
    slug: "huggies-pant",
    title: "Huggies Pant",
    type: "Health Care",
    unit: "32pcs",
    updatedAt: "2021-12-30T15:22:21.842Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e2",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.",
      image: images["product-280.png"],
    originalPrice: 8,
    parent: "Baby Care",
    quantity: 100,
    slug: "huggies-w-pant",
    title: "Huggies-W-Pant",
    type: "Health Care",
    unit: "24pcs",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e1",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.188Z",
    description:
      "Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.",
      image: images["product-281.png"],

    originalPrice: 9,
    parent: "Baby Care",
    quantity: 100,
    slug: "huggies-diaper",
    title: "Huggies Diaper",
    type: "Health Care",
    unit: "36pcs",
    updatedAt: "2021-12-22T16:41:23.188Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3e0",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.187Z",
    description:
      "Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.",
      image: images["product-282.png"],
    originalPrice: 12,
    parent: "Baby Care",
    quantity: 100,
    slug: "himalaya-powder",
    title: "Himalaya Powder",
    type: "Health Care",
    unit: "100g",
    updatedAt: "2021-12-22T16:41:23.187Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 0,
    tag: ["baby care", "baby accessories"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af3df",
    children: "Baby Accessories",
    createdAt: "2021-12-22T16:41:23.186Z",
    description:
      "Baby Products are products intended to be used on infants and children under the age of three. Baby products are specially formulated to be mild and non-irritating and use ingredients that are selected for these properties. Baby products include baby shampoos and baby lotions, oils, powders and creams.",
      image: images["product-283.png"],
    originalPrice: 8,
    parent: "Baby Care",
    quantity: 100,
    slug: "freshmaker-wipes",
    title: "Freshmaker Wipes",
    type: "Health Care",
    unit: "72pcs",
    updatedAt: "2021-12-22T16:41:23.186Z",
    sku: "",
    __v: 0,
  },
];

export const discountProducts = [
  {
    price: 10,
    discount: 17,
    tag: ["bell-pepper", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4e2",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-27.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 99,
    slug: "organic-bell-pepper",
    title: "Organic Bell Pepper",
    type: "Grocery",
    unit: "22ct",
    updatedAt: "2022-07-08T14:26:08.089Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 16.666666666666664,
    tag: ["pumpkin", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4df",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-30.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 118,
    slug: "mini-pumpkin",
    title: "Mini Pumpkin",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-08T14:26:08.090Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 14,
    discount: 7,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4dc",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-33.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 84,
    slug: "iglotex-cauliflower",
    title: "Iglotex Cauliflower",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-14T12:27:24.539Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 8.333333333333332,
    tag: ["beans", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4db",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-34.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "french-green-beans",
    title: "French Green Beans",
    type: "Grocery",
    unit: "16ct",
    updatedAt: "2022-01-20T05:19:07.596Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 13,
    discount: 7,
    tag: ["escarole", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d9",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.214Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-36.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 109,
    slug: "escarole",
    title: "Escarole",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2022-05-09T16:11:52.335Z",
    sku: "",
    __v: 0,
  },
  {
    price: 8,
    discount: 20,
    tag: ["mango", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d5",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-40.png"],
    originalPrice: 10,
    parent: "Fruits & Vegetable",
    quantity: 170,
    slug: "haitian-mango",
    title: "Haitian Mangoe",
    type: "Grocery",
    unit: "8ct",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 13,
    tag: ["cranberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d3",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-42.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 100,
    slug: "cranberries",
    title: "Cranberries",
    type: "Grocery",
    unit: "9.5oz",
    updatedAt: "2021-12-22T16:41:23.213Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 8,
    tag: ["clementine", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4d2",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-43.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 168,
    slug: "mandarin-clementine",
    title: "Mandarin Clementine",
    type: "Grocery",
    unit: "22lb",
    updatedAt: "2022-05-09T23:38:03.700Z",
    sku: "",
    __v: 0,
  },
  {
    price: 9,
    discount: 10,
    tag: ["organic food", "orange"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ca",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-51.png"],
    originalPrice: 10,
    parent: "Organic Food",
    quantity: 64,
    slug: "orange-imported",
    title: "Orange Imported",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-06-29T18:21:54.036Z",
    sku: "",
    __v: 0,
  },
  {
    price: 10,
    discount: 9.090909090909092,
    tag: ["organic food", "china apple"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c9",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-52.png"],
    originalPrice: 11,
    parent: "Organic Food",
    quantity: 59,
    slug: "china-fuji-apple",
    title: "China Fuji Apple",
    type: "Grocery",
    unit: "1kg",
    updatedAt: "2022-07-15T19:26:14.502Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 10,
    discount: 16.666666666666664,
    tag: ["organic food", "orange-jelly"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c7",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.213Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-54.png"],
    originalPrice: 12,
    parent: "Organic Food",
    quantity: 65,
    slug: "sugar-free-orange-jelly",
    title: "Sugar Free Orange Jelly",
    type: "Grocery",
    unit: "375gm",
    updatedAt: "2022-06-11T11:44:56.199Z",
    sku: "F0054",
    __v: 0,
  },
  {
    price: 10,
    discount: 9,
    tag: ["organic food", "pineapple"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4c5",
    children: "Organic Food",
    createdAt: "2021-12-22T16:41:23.212Z",
    description:
      "Organic food is food produced by methods complying with the standards of organic farming. Standards vary worldwide, but organic farming features practices that cycle resources, promote ecological balance, and conserve biodiversity.",
    image: images["product-56.png"],
    originalPrice: 11,
    parent: "Organic Food",
    quantity: 69,
    slug: "pineapple",
    title: "Pineapple",
    type: "Grocery",
    unit: "1pcs",
    updatedAt: "2022-06-09T03:33:49.059Z",
    sku: "",
    __v: 0,
  },
];
export const popularProducts = [
  {
    price: 12,
    discount: 0,
    tag: ["rainbow-chard", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fd",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-1.png"],

    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: -0,
    slug: "rainbow-chard",
    title: "Rainbow Chard",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-05-13T03:52:58.986Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["clementine", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fc",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-2.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "clementine",
    title: "Clementine",
    type: "Grocery",
    unit: "5ct",
    updatedAt: "2022-01-19T20:18:00.564Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["rainbow-peppers", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4fa",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-3.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "rainbow-peppers",
    title: "Rainbow Peppers",
    type: "Grocery",
    unit: "4ct",
    updatedAt: "2022-06-19T14:37:33.108Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["blueberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f9",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-4.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 29,
    slug: "blueberry",
    title: "Blueberry",
    type: "Grocery",
    unit: "25qt",
    updatedAt: "2022-07-16T15:41:09.773Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["calabaza-squash", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f8",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-5.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 42,
    slug: "calabaza-squash",
    title: "Calabaza Squash",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-05T14:57:19.980Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["lettuce", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f7",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-6.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 83,
    slug: "lettuce",
    title: "Lettuce",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-07-11T05:18:44.963Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["radicchio", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f6",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-7.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "radicchio",
    title: "Radicchio",
    type: "Grocery",
    unit: "12ct",
    updatedAt: "2022-01-19T04:32:10.877Z",
    sku: "F0056",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["parsley", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f5",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.216Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-8.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 54,
    slug: "parsley",
    title: "Parsley",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-05T18:39:53.960Z",
    sku: "",
    __v: 0,
  },
  {
    price: 13,
    discount: 0,
    tag: ["strawberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f4",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-9.png"],
    originalPrice: 13,
    parent: "Fruits & Vegetable",
    quantity: 13,
    slug: "strawberrie",
    title: "Strawberrie",
    type: "Grocery",
    unit: "25oz",
    updatedAt: "2022-07-15T11:19:42.480Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f3",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-10.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 76,
    slug: "cauliflower",
    title: "Cauliflower",
    type: "Grocery",
    unit: "35lb",
    updatedAt: "2022-06-29T00:55:21.217Z",
    sku: "",
    __v: 0,
  },
  {
    price: 11,
    discount: 0,
    tag: ["cauliflower", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f2",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-11.png"],
    originalPrice: 11,
    parent: "Fruits & Vegetable",
    quantity: 110,
    slug: "organic-purple-cauliflower",
    title: "Organic Purple Cauliflower",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-06-17T15:38:38.622Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["squash", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f1",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-12.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 0,
    slug: "ahold-acorn-squash",
    title: "Ahold Acorn Squash",
    type: "Grocery",
    unit: "1ct",
    updatedAt: "2022-05-09T07:02:59.121Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["cabbage", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4f0",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-13.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 89,
    slug: "bok-choy-cabbage",
    title: "Bok Choy Cabbage",
    type: "Grocery",
    unit: "1lb",
    updatedAt: "2022-07-12T18:06:19.481Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["strawberries", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ef",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-14.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 80,
    slug: "strawberries-package",
    title: "Strawberries Package",
    type: "Grocery",
    unit: "25oz",
    updatedAt: "2022-06-17T12:14:43.031Z",
    sku: "",
    __v: 0,
  },
  {
    price: 15,
    discount: 0,
    tag: ["aloe-vera-leaf", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ee",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-15.png"],
    originalPrice: 15,
    parent: "Fruits & Vegetable",
    quantity: 94,
    slug: "aloe-vera-leaf",
    title: "Aloe Vera Leaf",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-06-17T12:14:43.031Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["pineapple", "fresh fruits"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ed",
    children: "Fresh Fruits",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "In a botanical sense, a fruit is the fleshy or dry ripened ovary of a flowering plant, enclosing the seed or seeds. Apricots, bananas, and grapes, as well as bean pods, corn grains, tomatoes, cucumbers, and (in their shells) acorns and almonds, are all technically fruits.",
    image: images["product-16.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 49,
    slug: "pineapple-imported",
    title: "Pineapple Imported",
    type: "Grocery",
    unit: "5lb",
    updatedAt: "2022-06-26T18:26:14.825Z",
    sku: "",
    __v: 0,
  },
  {
    price: 12,
    discount: 0,
    tag: ["broccoli", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4ec",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-17.png"],
    originalPrice: 12,
    parent: "Fruits & Vegetable",
    quantity: 145,
    slug: "organic-broccoli",
    title: "Organic Broccoli",
    type: "Grocery",
    unit: "10oz",
    updatedAt: "2022-06-06T17:25:20.435Z",
    sku: "",
    __v: 0,
  },
  {
    price: 14,
    discount: 0,
    tag: ["avocado", "fresh vegetable"],
    flashSale: false,
    status: "Show",
    id: "61c355337f19aa31349af4eb",
    children: "Fresh Vegetable",
    createdAt: "2021-12-22T16:41:23.215Z",
    description:
      "Most fresh vegetables are low in calories and have a water content in excess of 70 percent, with only about 3.5 percent protein and less than 1 percent fat. ... The root vegetables include beets, carrots, radishes, sweet potatoes, and turnips. Stem vegetables include asparagus and kohlrabi.",
    image: images["product-18.png"],
    originalPrice: 14,
    parent: "Fruits & Vegetable",
    quantity: 146,
    slug: "organic-pinkerton-avocado",
    title: "Organic Pinkerton Avocado",
    type: "Grocery",
    unit: "each",
    updatedAt: "2022-07-08T14:35:27.983Z",
    sku: "",
    __v: 0,
  }
  
];

export function getProductById(title) {
  return Products.find((product) => product.slug === title);
}

export function getProductByChildrenCategory(category) {
  return Products.filter(
    (product) => product.children.toLowerCase() === category.toLowerCase()
  );
}

export function getProductByParentCategory(category) {
  return Products.filter(
    (product) => product.parent.toLowerCase() === category.toLowerCase()
  );
}

export function getProductByName(value) {
  return Products.filter((product) =>
    product.title.toLowerCase().includes(value.toLowerCase())
  );
}
