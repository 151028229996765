import { createSlice } from "@reduxjs/toolkit";

// Define a Redux slice for managing authentication state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token'),
    isLoggedIn: !!localStorage.getItem('token'),
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      state.isLoggedIn = true;
      localStorage.setItem('token', action.payload);
    },
    removeToken(state) {
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('token');
    },
  },
});

// Export actions and reducer from the auth slice
export const { setToken, removeToken } = authSlice.actions;



export const authReducer = authSlice.reducer;