import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const shippingApiSlice = createApi({
  reducerPath: 'shippingApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    fetchShippingByCode: builder.query({
      query: (id) => `/shipping/code/${id}`,
    }),
  }),
});

export const { 
  useFetchShippingByCodeQuery,
} = shippingApiSlice;

export const shippingApiReducer = shippingApiSlice.reducer;

