import React, { useEffect } from "react";

import CategoryCard from "../categoryCard/CategoryCard";
import ProductList from "../productList/ProductList";
// import SliceCategory from "../sliceCategory/SliceCategory";
import NotFoundProduct from "../notFoundProduct/NotFoundProduct";
import { useSearchParams } from "react-router-dom";
import { useFetchProductsByCategoryQuery } from "../../store/api/ProductSlice";
const Search = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const [SearchParams] = useSearchParams();
  const search = SearchParams.get('category');

  
  const { data, isLoading } = useFetchProductsByCategoryQuery(search);


  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="flex pt-10 lg:py-12">
          <div className="flex w-full">
            <div className="w-full">
              <CategoryCard />
              {/* <SliceCategory /> */}

              {!isLoading && data.length >= 1 && (
                <ProductList data={data} />
              )}
              {isLoading && (

                <div role="status" className=" m-10 animate-pulse ">
                  <div className="h-48 bg-gray-300 dark:bg-gray-200 w-full rounded mb-4 text-black font-lg text-center py-20">Loading...</div>
                </div>
              )}

              {!isLoading && data.length < 1 && <NotFoundProduct/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
