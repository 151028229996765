import React, { useEffect } from 'react'
import verifiedemail from '../../assets/img/verifiedemail.jpeg';
import { Link, useParams } from 'react-router-dom';
import { useRegisterUserMutation } from '../../store/api/AuthSlice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/reducers/auth';

const EmailVerify = () => {
  const {token} = useParams();
  const [registerUser] = useRegisterUserMutation();
  const dispatch = useDispatch();

  const Register = async()=>{
    const result = await registerUser(token);
    if(result?.error){
      toast.error(result?.error?.data?.message);
    }else{
      dispatch(setToken(result?.data?.token)); 
      toast.success(result?.data?.message);
    }
  }
  useEffect(()=>{
    return ()=>Register();
   // eslint-disable-next-line
  },[]);

  return (
    <div className='py-4 text-center mb-12'> 
        <img src={verifiedemail} alt="" className='w-48 m-auto -mt-10' />
        <p className='text-3xl font-serif font-bold text-center text-black -mt-4 mb-12'>Thank You</p>
        <Link to="/user/dashboard" className='bg-green-500 rounded px-8 py-4 text-lg text-white hover:text-white'>Back to Dashboard</Link>
    </div>
  )
}

export default EmailVerify